<template>
  <div id="search-product">
    <div class="search-block">
      <input class="mega-search" v-model="querySearch" v-on:keyup="searchProcess()" v-on:keydown.enter.prevent="searchProcess"/>
      <button class="mega-search-button" v-on:click="searchProcess()"><img style="opacity:0.6; width:16px; height: 16px;" src="/img/search.svg" /></button>
    </div>
    <div v-if="findData.length > 0" class="search-result" > <!--  -->
      <div class="row">
        <div class="col-6">
          <router-link
              class="search-product-link"
              :to="{name: 'SearchPage', params: { id: querySearch } }"
              target="_blank"
          >
            <span>Все результаты</span>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <button class="search-close" v-on:click="searchClose()">X</button><br>
        </div>
      </div>
      <div class="result-search-products">
        <div class="row search-row" v-for="item in findData" :key="item._id">
          <div class="col-2">
            <img
                style="width: 50px; max-height: 50px; border:1px solid #e5e5e5; margin:5px;"
                :src="'https://exchange.partner.su/img/' + item.img_path[0]"
            />
          </div>
          <div class="col-6">
            <div>Артикул: {{ item.code }}</div><br>
            <router-link
                class="search-product-link"
                :to="{name: 'ShopProduct', params: { slug: item.slug } }"
            >
              <div style="margin:5px;">{{ item.g_name }}</div>
            </router-link>
          </div>
          <div class="col-2">
            <div style="font-weight: bold;margin:12px 0px;">{{ item.prices[0].price }}руб.</div>
          </div>
          <div class="col-2">
            <button class="button-buy" v-on:click="addToCart(item, 1)">В корзину</button>
          </div>
        </div>
      </div>
      <!-- {{ findData }} -->
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "SearchProduct",
  data() {
    return {
      querySearch:"",
      findData: [],
    }
  },
  methods: {
    async ABBA(s) {
      let a = s.split(" ");

      if( a.length < 2) return s;
        return [ a[1], a[0]].concat( a.splice( 2, a.length)).join(" ");
    },
    async layoutFix(str) {
      let replacer = {
        "q":"й", "w":"ц"  , "e":"у" , "r":"к" , "t":"е", "y":"н", "u":"г",
        "i":"ш", "o":"щ", "p":"з" , "[":"х" , "]":"ъ", "a":"ф", "s":"ы",
        "d":"в" , "f":"а"  , "g":"п" , "h":"р" , "j":"о", "k":"л", "l":"д",
        ";":"ж" , "'":"э"  , "z":"я", "x":"ч", "c":"с", "v":"м", "b":"и",
        "n":"т" , "m":"ь"  , ",":"б" , ".":"ю" , "/":".",
      };

      let replace = "";

      for(let i=0; i < str.length; i++){

        if( replacer[ str[i].toLowerCase() ] != undefined){

          if(str[i] == str[i].toLowerCase()){
            replace = replacer[ str[i].toLowerCase() ];
          } else if(str[i] == str[i].toUpperCase()){
            replace = replacer[ str[i].toLowerCase() ].toUpperCase();
          }

          str = str.replace(str[i], replace);
        }
      }

      return str;
    },
    async searchProcess() {

      if(this.querySearch.length > 3) {
        //await alert(await this.layoutFix(this.querySearch));

        const variant = await this.ABBA(this.querySearch);
        const variant2wws = variant.replace(/\s/g, '');
        const variant3wws = this.querySearch.replace(/\s/g, '');
        const variant4 = await this.layoutFix(this.querySearch);
        const variant5 = await this.ABBA(variant4);
        const variant6 = this.querySearch.replace(/\s/g, '-');
        const variant7 = variant4.replace(/\s/g, '-');

        let SearchToServer = {
          $or: [
            {
              code: {
                $regex: this.querySearch,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: this.querySearch,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant2wws,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant3wws,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant4,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant5,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant6,
                $options: "i"
              }
            },
            {
              g_name: {
                $regex: variant7,
                $options: "i"
              }
            }
          ]
        };
        axios
            .post('https://api.partner.su/shop/search/limit',
                SearchToServer,
                {
                  //withCredentials: true,
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    //'Authorization' : `Bearer ${myToken}`
                  }
                }

            )
            .then((response) => {
              this.findData = response.data.products_list;

              // Парсим цены
              for (let p = 0; p < this.findData.length; p++) {

                this.findData[p].prices[0].price = parseFloat(this.findData[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));

              }

            });
      }

    },
    searchClose() {
      this.findData = [];
    },
    // Добавление в корзину
    addToCart(product, quantity) {

      let quantityValue;

      if(quantity == null) {
        quantityValue = 1;
      } else {
        quantityValue = parseInt(quantity);
      }

      this.$store.dispatch('addProductToCart', {
        product: product,
        quantity: quantityValue,
      });
    },
  },
  computed: {

  },
  watch: {

  },
  created() {

  }
}
</script>

<style scoped>
.search-block {
  margin-top: 18px;
  min-width: 300px;
}
.mega-search {
  min-width: 260px;
  height: 34px;
  border-radius: 0px;
  background-color: #f8fafc;
  border: 1px solid #d5dadc;
}
.mega-search-button {
  border:0px solid #e5e5e5;
  background: #e5e5e5;
  height: 34px;
  width: 34px;
}
.search-result {
  width:800px;
  height: 300px;
  padding: 20px;
  margin:10px -200px;
  position: absolute;
  border:1px solid #e5e5e5;
  background: #FFFFFF;
  overflow: hidden;
  overflow-y: scroll;
}
.search-close {
  background: #ED2924;
  color: #FFFFFF;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: bold;
  border:0px;
  float: right;
  margin:10px;
}
.search-close:hover {
  background: #666666;
}
.result-search-products {

}
.search-row {
  border-bottom: 1px solid #e5e5e5;
}
.search-product-link {
  color:#222222;
}
.button-buy {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 4px;
  letter-spacing: 1px;
  color: #FFF;
  background: #00A100;
  text-transform: uppercase;
  border: 1px solid #00A100;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px;
  margin:10px 0px 10px 0px;
}
.button-buy:hover {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 3px;
  color: #FFF;
  background: #2dcb00;
  text-transform: uppercase;
  border: 1px solid #2dcb00;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
}
</style>