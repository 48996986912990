<template>
  <div id="userCabinet">
    <div v-if="this.$store.state.AuthUser.userInfo.status == 'Ok'">
      <h1>Личный кабинет</h1>
      <div class="row">
        <div class="col-12 col-sm-8">
          <h3>Ваши заказы</h3>
          <div class="order-elm" v-for="order in this.$store.state.AuthUser.userInfo.userOrders" :key="order._id">
            <div class="row">
              <div class="col-12 col-sm-8">
                <div class="order-num">
                  <div @click="activate(order._id)" class="order-num-a">
                    Номер заказа: №{{ order.number }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div @click="cancelOrder(order._id)">
                  Отменить заказ
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <div>Стоимость товара: {{ order.order_price }}</div>
                <div>Тип доставки: {{ order.delivery_type }}</div>
                <div>Тип оплаты: {{ order.pay_type }}</div>
                <div>Статус заказа: {{ order.order_status }}</div>
              </div>
              <div class="col-12 col-sm-6">
                <div>Вес заказа: {{ order.order_weight }}</div>
                <div>Cтоимость доставки: {{ order.delivery_price }}</div>
                <div>Полная стоимость заказа: {{ order.all_price }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div>Пункт выдачи CDEK: {{ order.delivery_pvz }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div><b>Комментарий к заказу</b>: <br>{{ order.order_comment }}</div>
              </div>
            </div>
            <div class="product-in-order" :class="{ active : active_el == order._id }">
              <br>
              <h3 style="font-size: 16px; font-weight: bold;">Содержимое заказа:</h3>
              <div v-for="product in order.products" :key="product.id">
                <div class="product-in-order-elm">
                  <div class="row">
                    <div class="col-2">
                      <img class="order-in-img" :src="'https://exchange.partner.su/img/' + product.img_path" alt="">
                    </div>
                    <div class="col-6">
                      <router-link :to="'/products/' + product.slug" target="_blank">{{ product.name }}</router-link>
                    </div>
                    <div class="col-2">
                      {{ product.quantity }}шт.
                    </div>
                    <div class="col-2">
                      {{ product.sum }}р.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="client-info">
            <h3>Реквизиты</h3>
            <div>
              ID пользователя: {{ this.$store.state.AuthUser.userInfo.userData._id }}
            </div>
            <!-- Для Физ.лиц -->
            <div v-if="this.$store.state.AuthUser.userInfo.userData.fl_fio != ''">
              Ф.И.О: {{ this.$store.state.AuthUser.userInfo.userData.fl_fio }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.fl_mail != ''">
              E-mail: {{ this.$store.state.AuthUser.userInfo.userData.fl_mail }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.fl_phone != ''">
              Телефон: {{ this.$store.state.AuthUser.userInfo.userData.fl_phone }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.fl_city != ''">
              Город: {{ this.$store.state.AuthUser.userInfo.userData.fl_city }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.fl_address != ''">
              Адрес доставки: {{ this.$store.state.AuthUser.userInfo.userData.fl_address }}
            </div>
            <!-- Для ЮЛ -->
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_name != ''">
              Наименование компании: {{ this.$store.state.AuthUser.userInfo.userData.ul_name }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_inn != ''">
              ИНН: {{ this.$store.state.AuthUser.userInfo.userData.ul_inn }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_kpp != ''">
              КПП: {{ this.$store.state.AuthUser.userInfo.userData.ul_kpp }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_address != ''">
              Юр.адрес: {{ this.$store.state.AuthUser.userInfo.userData.ul_address }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_contact != ''">
              Контактное лицо: {{ this.$store.state.AuthUser.userInfo.userData.ul_contact }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_phone != ''">
              Тел.: {{ this.$store.state.AuthUser.userInfo.userData.ul_phone }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_contact != ''">
              Контактное лицо: {{ this.$store.state.AuthUser.userInfo.userData.ul_contact }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_mail != ''">
              E-mail: {{ this.$store.state.AuthUser.userInfo.userData.ul_mail }}
            </div>
            <div v-if="this.$store.state.AuthUser.userInfo.userData.ul_delivery != ''">
              Адрес доставки(отличается от Юр.адреса): {{ this.$store.state.AuthUser.userInfo.userData.ul_delivery }}
            </div>
            <div class="exit-button">
              <button v-on:click="logOut()" class="log-out-button">Выйти</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h1>Авторизация</h1>
      <div class="alert alert-danger">
        Для просмотра личных данных необходимо авторизоваться.
      </div>
      <button>
        <router-link :to="{name: 'AuthUser'}">Авторизоваться</router-link>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "userCabinet",
  data () {
    return {
      active_el:0,
      cancel_el:0,
    }
  },
  methods: {
    activate:function(el){
      if (this.active_el === el) {
        this.active_el = 0;
      } else {
        this.active_el = el;
      }
    },
    cancelOrder:function(el) {
      if (this.cancel_el === el) {
        this.cancel_el = 0;
      } else {
        this.cancel_el = el;
      }
    },
    logOut() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
  .order-num {
    font-weight:bold;
  }
  .order-num-a {
    text-decoration: none;
    color:#111111;
  }
  .order-num-a:hover {
    text-decoration: underline;
    color:#111111;
    cursor:pointer;
  }
  .order-elm {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    border:1px solid #e5e5e5;
    margin-bottom: 15px;
  }
  .client-info {
    background: #F2F2F2;
    border-radius: 5px;
    width: 100%;
    height: auto;
    padding: 15px;
  }
  .product-in-order {
    display: none;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-top:1px solid #e5e5e5;
    position:relative;
  }
  .active {
    display: block;
  }
  .product-in-order-elm {
    width: 100%;
    height: auto;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
  }
  .product-in-order-elm a {
    color:#111111;
  }
  .order-in-img {
    max-width:80px;
    height: auto;
    max-height: 100px;
    border:1px solid #e5e5e5;
    padding:5px;
  }
  .log-out-button {
    width: 80px;
    padding: 8px 6px;
    margin-top: 8px;
    background: #ED2924;
    border-radius: 5px;
    border:unset;
    color: #FFF;
    font-size: 12px;
    text-align: center;
    font-family: Tahoma;
    text-decoration: none;
    text-transform: uppercase;
  }
  .log-out-button:hover {
    background: #D6221D;
  }
</style>