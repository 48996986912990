<template>
  <div class="academy-sidebar-left">
    <div class="row">
      <div class="col-12">
        <img src="/img/banner-calchim.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcademySidebarLeft"
}
</script>

<style scoped>

</style>