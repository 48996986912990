<template>
  <div id="search-page">
    <div class="result-search-products">
      <h1>Все результаты поиска</h1>
      <div class="row search-row" v-for="item in findData" :key="item._id">
        <div class="col-2">
          <img
              style="width: 50px; max-height: 50px; border:1px solid #e5e5e5; margin:5px;"
              :src="'https://exchange.partner.su/img/' + item.img_path[0]"
          />
        </div>
        <div class="col-6">
          <router-link
              class="search-product-link"
              :to="{name: 'ShopProduct', params: { slug: item.slug } }"
          >
            <div style="margin:12px 0px;" >{{ item.g_name }}</div>
          </router-link>
        </div>
        <div class="col-2">
          <div style="font-weight: bold; margin:12px 0px;">{{ item.prices[0].price }}руб.</div>
        </div>
        <div class="col-2">
          <button class="button-buy" v-on:click="addToCart(item, 1)">В корзину</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  props: ['id'],
  name: "SearchPage",
  data () {
    return {
      page: 1,      // Пагинация текущая страница
      perPage: 48,  // Пагинация кол-во элементов на странице
      findData: [],
    }
  },
  methods: {
    searchProcess() {
      let SearchToServer = {
        $or: [
          {
            code: {
              $regex: this.id,
              $options: "i"
            }
          },
          {
            g_name: {
              $regex: this.id,
              $options: "i"
            }
          }
        ]
      };
      axios
          .post('https://api.partner.su/shop/search/list',
              SearchToServer,
              {
                //withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  //'Authorization' : `Bearer ${myToken}`
                }
              }

          )
          .then((response) => {
            this.findData = response.data.products_list;

            // Парсим цены
            for (let p = 0; p < this.findData.length; p++) {

              this.findData[p].prices[0].price = parseFloat(this.findData[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));

            }

          });
    },
    // Пагинация
    setPages (products) {
      this.pages = [];
      let numberOfPages = Math.ceil(products.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate (products) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);

      return products.slice(from, to);

    },
    // Добавление в корзину
    addToCart(product, quantity) {

      let quantityValue;

      if(quantity == null) {
        quantityValue = 1;
      } else {
        quantityValue = parseInt(quantity);
      }

      this.$store.dispatch('addProductToCart', {
        product: product,
        quantity: quantityValue,
      });
    },
  },
  $route: {
    immediate: true,
    handler() {
      this.searchProcess();
    }
  },
  created() {
    this.searchProcess();
  }

}
</script>

<style scoped>
.result-search-products {

}
.search-row {
  border-bottom: 1px solid #e5e5e5;
}
.search-product-link {
  color:#222222;
}
.button-buy {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 4px;
  letter-spacing: 1px;
  color: #FFF;
  background: #00A100;
  text-transform: uppercase;
  border: 1px solid #00A100;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px;
  margin:10px 0px 10px 0px;
}
.button-buy:hover {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 3px;
  color: #FFF;
  background: #2dcb00;
  text-transform: uppercase;
  border: 1px solid #2dcb00;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
}
</style>