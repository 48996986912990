//import Axios from "axios";

//import cart from "../../components/cart";

export default {
    state: {
        cart:[],
    },
    mutations: {
        ADD_TO_CART(state, {product, quantity}) {

            let productInCart = state.cart.find(
                item => {
                    return item.product.code === product.code;
                }
            );

            if(productInCart) {
                productInCart.quantity += quantity;
                return;
            }

            state.cart.push({
                product,
                quantity
            })
        },
        REMOVE_PRODUCT_FROM_CART(state, product) {
            state.cart = state.cart.filter(item => {
                return item.product.code !== product.code;
            })
        },
        clearUserOrder (state) {
            state.cart = [];
            //this.$store.replaceState(state.cart);
        }
    },
    actions: {
        addProductToCart({commit}, {product, quantity}){
            commit('ADD_TO_CART', { product, quantity });
        },
        clearCart({commit}){
            commit('clearUserOrder');
        },
        removeProductFromCart({commit}, product) {
            commit('REMOVE_PRODUCT_FROM_CART', product);
        }
    },
    getters: {
        cartTotalPrice(state) {
            let total = 0;
            state.cart.forEach(item => {
               total += item.product.prices[0].price * item.quantity;
            });

            return total;
        }
    }
}

