<template>
  <div id="news-item">
    <div class="row">
      <div class="col-12">
        <h1>{{ pageData[0].headerPage }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-html="pageData[0].contentPage" class="news-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewsItem",
  props: ['id'],
  data() {
    return {
      pageData: null,
    }
  },
  components: {

  },
  methods: {

    // Запрос страницы
    async fetchPage() {
      axios.get('https://api.partner.su/site/one-page-by-slug',
          {
            params:{
              id: this.id
            }
          }

      )
      .then(response => {

        this.pageData = response.data.pages_list;

      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  created() {
    this.scrollToTop();
    this.fetchPage();
  }
}
</script>

<style scoped>
#news-item h1 {
  font-size: 2em;
  font-weight: bold;
}
.news-content {

}
</style>