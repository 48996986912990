import Axios from "axios";

export default {

    state: {
        userInfo: [],
        statusAuth: "Введите логин и пароль",
        //apiHeaders:[]
    },
    mutations: {
        loginUser (state, userData) {
            state.userInfo = userData;
            state.statusAuth = "Пользователь авторизован!";
            //state.apiHeaders = headersData;
        },
        authError (state, userData) {
            state.statusAuth = userData;
            //state.apiHeaders = headersData;
        },
        logoutUser (state) {
            state.userInfo = [];
            state.statusAuth = "Введите логин и пароль";
        }
    },

    actions: {
        login({ commit }, payload) {
            Axios.post(
                'https://api.partner.su/shop/user-profile',
                {
                    'user': payload.loginUser,
                    'pass': payload.passwordUser
                },
                {
                    //withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*', // https://api.partner.su/
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    }
                }
            ).then( response => {
                if(response.data.status != "NONE!") {
                    commit("loginUser", response.data);
                    window.location.href = "/cabinet";
                } else {
                    commit("authError", response.data.answer)
                }

            });
        },
        logout({ commit }) {
            commit("logoutUser");
        }
    },
    getters: {

    }
}

