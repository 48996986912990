<template>
  <div id="academy-item">
    <div class="row">
      <div class="col-12">
        <h1>{{ pageData[0].headerPage }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8">
        <div v-html="pageData[0].contentPage" class="academy-content"></div>
      </div>
      <div class="col-12 col-sm-4">
        <AcademySidebarRight />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AcademySidebarRight from "@/components/blog/AcademySidebarRight";

export default {
  name: "AcademyItem",
  props: ['id'],
  data() {
    return {
      pageData: null,
    }
  },
  components: {
    AcademySidebarRight

  },
  methods: {

    // Запрос страницы
    async fetchPage() {
      axios.get('https://api.partner.su/site/one-page-by-slug',
          {
            params:{
              id: this.id
            }
          }
      )
      .then(response => {

        this.pageData = response.data.pages_list;

      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
    },

    // Скролл наверх
    scrollToTop() {
      window.scrollTo(0,0);
    },

  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchPage();
      }
    },
  },
  created() {
    this.scrollToTop();
    this.fetchPage();
  }
}
</script>

<style scoped>
#academy-item h1 {
  font-size: 2em;
  font-weight: bold;
}
.academy-content {

}
</style>