<template>
  <div id="ShopCart">
    <h1 style="font-size: 1.75rem;">Корзина</h1>
    <div id="success-window-pay" :class="{ activePayWindow }">
      <div class="thanks-elm">
        <span style="font-weight: bold;">СПАСИБО ЗА ВАШ ЗАКАЗ!</span><br>
      </div>
      <div class="pay-block">
        <button>Оплатить заказ</button>
      </div>
      <div class="mail-pass">
        <span style="font-size: 14px;">* На вашу почту отправлены логин и пароль для доступа к личному кабинету</span>
      </div>
    </div>
    <div class="cart-order-block fadeIn">
      <div v-for="item in cart" :key="item.product._id">
        <div class="row cart-elm">
          <div class="col-2">
            <img style="border:1px solid #CCC; width:60px;" :src="'https://exchange.partner.su/img/' + item.product.img_path[0]" />
          </div>
          <div class="col-4">
            <span style="color:#666666; font-size:10px;">Код товара: {{ item.product.code }}</span><br>
            {{ item.product.g_name }}
          </div>
          <div class="col-2">
            <br>
            {{ item.product.prices[0].price }}р
          </div>
          <div class="col-1">
            <br>
            {{ item.quantity }}шт
          </div>
          <div class="col-1">
            <br>
            {{ (item.product.prices[0].price * item.quantity).toFixed(2) }}р
          </div>
          <div class="col-2" style="text-align: right">
            <br>
            <button class="removeButton" @click.prevent="removeProductFromCart(item.product)">X</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <br>
          <button class="clearButton" v-on:click="clearOrder()">Очистить заказ</button>
        </div>
        <div class="col-9" style="text-align: right">
          <br>
          <b>Кол-во позиций: </b>
          <span style="font-size:14px;">{{ this.$store.state.ShopCart.cart.length }}шт.</span><br>
          <b>Общая стоимость товара: </b>
          <span style="font-size:14px;">{{ this.$store.getters.cartTotalPrice.toFixed(2) }}р.</span><br>
          <b>Общая вес товара: </b>
          <span style="font-size:14px;">{{ full_weight }}г</span><br>
        </div>
      </div>
      <br>
    </div>
    <div class="order-block">
      <div class="row">
        <div class="col-12">
          <h3 class="order-header">Оформление заказа</h3>
          Receipt для отправки в Робокасса:  {{ robokassa_receipt }}
        </div>
      </div>
      <b-tabs card>
        <b-tab title="Физическое лицо" active>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_fio" placeholder="Ф.И.О (пример: Иванов Иван Иванович)"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_mail" placeholder="E-mail (пример: ivanov@mail.ru)"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_phone" placeholder="Телефон (пример: 89101234567)"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_city" @keyup="answer_city_DaData()" placeholder="Город (пример: Санкт-Петербург)"/>
              <div class="dadata_city_answer" :class="{ active : active_dadata_window == true }">
                <div class="find-city" v-for="city in dadata_city_answer" :key="city.value">
                  <p v-on:click="dadata_city_select(city.value, city.data.kladr_id)" style="padding: 15px;">
                    <span style="color:#222; font-weight: bold;">{{ city.value }}</span> - <span style="color:#666;">{{ city.data.kladr_id}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div v-if="order_delivery_type === 'Самовывоз'" class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_address" placeholder="Адрес доставки (пример: ул.Варшавская, д.51, к. 1, лит. А, пом. 16)"/>
            </div>
          </div>
          -->
          <br>
          <div class="row cdek-data-pvz" :class="{ active : active_cdek_pvz == true }">
            <div class="col-12 col-sm-6">
              <h5>Пункт выдачи СDEK в Вашем городе:</h5>
              <select class="select-pvt" v-model="delivery_pvz">
                <option disabled value="">Пункт выдачи СDEK в Вашем городе: </option>
                <option v-for="item in pvz" :key="item.code">
                  "{{ item.name }}" - {{ item.location.address_full }}
                </option>
              </select>
            </div>
          </div>
          <br>
          <br>
          <div class="row">
            <div class="col-12">
              <h3 class="order-sub-header">Тип доставки: {{ order_delivery_type }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <button class="order-button-delivery-type-1" v-on:click="orderDelivery('Самовывоз')">&nbsp;</button>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <button class="order-button-delivery-type-2" v-on:click="orderDelivery('CDEK')">&nbsp;</button>
                </div>
                <div class="col-12 col-sm-8">
                  <div v-if="cdek_data_delivery.total_sum > 0" class="cdek-data-window" >
                    Стоимость доставки: {{ cdek_data_delivery.total_sum }}р.<br>
                    Доставим: от {{ cdek_data_delivery.calendar_min }} до {{ cdek_data_delivery.calendar_max }} дней<br>
                    Вес посылки: {{ full_weight }}г
                  </div>
                </div>
              </div>

            </div>
            <br>
            <br>
          </div>
          <br>
          <br>
          <div class="row">
            <div class="col-12">
              <h3 class="order-sub-header">Тип Оплаты: {{ order_pay_type }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <button v-on:click="orderPayType('При получении')" class="order-button-pay-type-1">&nbsp;</button>
            </div>
            <div class="col-4">
              <button v-on:click="orderPayType('Счет на оплату')" class="order-button-pay-type-2">&nbsp;</button>
            </div>
            <div class="col-4">
              <button v-on:click="orderPayType('Кредитной картой')" class="order-button-pay-type-3">&nbsp;</button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Юридическое лицо">
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" @keyup="answer_company_DaData()" v-model="order_ul_name" placeholder="Название компании"/>
              <div class="dadata_company_answer" :class="{ active : active_dadata_company_window == true }">
                <div class="find-company" v-for="company in dadata_company" :key="company.inn">
                  <p v-on:click="dadata_company_select(company.value, company.data.inn, company.data.kpp, company.data.ogrn, company.data.address.value)" style="padding: 15px;">
                    <span style="color:#222; font-weight: bold;">{{ company.value }}</span> - ИНН {{ company.data.inn }} / КПП {{ company.data.kpp }}<br>
                    <span style="color:#666;">ОГРН {{ company.data.ogrn }}</span><br>
                    Юр. адрес: {{ company.data.address.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_address" placeholder="Юридический адрес"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_inn" placeholder="ИНН"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_kpp" placeholder="КПП"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_ogrn" placeholder="ОГРН"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_contact" placeholder="Контактное лицо"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_mail" placeholder="E-Mail"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_phone" placeholder="Телефон"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <input class="order-input" v-model="order_ul_delivery" placeholder="Адрес доставки"/>
            </div>
          </div>
          <br>
          <br>
          <div class="row">
            <div class="col-12">
              <h3 class="order-sub-header">Тип доставки: {{ order_delivery_type }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <button class="order-button-delivery-type-1" v-on:click="orderDelivery('Самовывоз')">&nbsp;</button>
            </div>
            <div class="col-6">
              <button class="order-button-delivery-type-3" v-on:click="orderDelivery('Транспортной компанией')">&nbsp;</button>
            </div>
          </div>
          <br>
          <br>
          <div class="row">
            <div class="col-12">
              <h3 class="order-sub-header">Тип Оплаты: {{ order_pay_type }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <button v-on:click="orderPayType('Счет на оплату')" class="order-button-pay-type-2">&nbsp;</button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div class="row">
        <div class="col-12">
          <div class="full-price">
            Общая стоимость товара: <b>{{ full_price }}р.</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Стоимость доставки: <b>{{ delivery_price }}р.</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Общая стоимость заказа:<b>{{ all_price }}р.</b><br><br>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-12">
          <h3 style="font-size: 21px;">Комментарий к заказу</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <textarea
              style="width:100%; min-height: 100px;"
              v-model="order_comment"
              placeholder="Здесь вы можете оставить комментарий к заказу"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button v-on:click="orderCreate()" class="order-button-create">Оформить заказ</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          Нажимая кнопку «Оформить заказ», я даю свое согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей, определенных в Согласии на обработку персональных данных
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Axios from "axios";

export default {
  name: "ShopCart",
  data() {
    return {
      order_ul_name: "",
      order_ul_address: "",
      order_ul_inn: "",
      order_ul_kpp: "",
      order_ul_ogrn: "",
      order_ul_contact: "",
      order_ul_mail: "",
      order_ul_phone: "",
      order_ul_delivery: "",
      order_fio: "",
      order_mail: "",
      order_phone: "",
      order_city: "",
      order_address: "-",
      order_comment: "",
      order_delivery_type: "Самовывоз",
      order_pay_type: "Счет на оплату",
      robokassa_receipt: "",
      robokassa_data_example: {
        "sno":"osn",
        "items": [
          {
            "name": "Название товара 1",
            "quantity": 1,
            "sum": 100,
            "payment_method": "full_payment",
            "payment_object": "commodity",
            "tax": "vat10"
          },
          {
            "name": "Название товара 2",
            "quantity": 3,
            "sum": 450,
            "payment_method": "full_prepayment",
            "payment_object": "excise",
            "nomenclature_code": "04620034587217"
          }
        ]
      },
      activePayWindow: false,
      cdek_token: [],
      dadata_city_answer: [],
      dadata_select_city: "",
      dadata_kladr_id: "",
      dadata_company:"",
      active_dadata_window: false,
      active_dadata_company_window: false,
      cdek_city_code: [],
      cdek_data_delivery: [],
      full_weight: "",
      pvz: [],
      delivery_pvz: "",
      active_cdek_pvz: false,
      full_price: 0,
      delivery_price: 0,
      delivery_price_cdek: 0,
      all_price: 0,
    }
  },
  computed: {
    cart() {
      return this.$store.state.ShopCart.cart;
    },
  },
  methods: {
   async cdek_auth () {

     if (this.cdek_city_code.length > 0) {

       await this.$store.dispatch('unset_cdek_auth_info');

       const url = "https://api.partner.su/cdek/auth";
       const config = {
         //withCredentials: true,
         headers: {
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Methods': 'POST',
         }
       };
       const query = await Axios.post(url,{ letMeAuth: 1}, config);
       await this.$store.dispatch('add_cdek_auth_info', query.data);
       await this.cdek_calculator();

     }

      /*
      if (this.$store.state.Cdek.cdek_info.length > 0) {

        this.cdek_calculator();

      }
      */
    },
   async cdek_calculator () {

      Axios.post('https://api.partner.su/cdek/calc',
          {
            cdek_city: this.cdek_city_code,
            weight: this.full_weight,
            token: this.$store.state.Cdek.cdek_info[0].cdek_answer.access_token
          },
          {
            //withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
            }
          }
      ).then((response) => {

        this.cdek_data_delivery = response.data.cdek_answer;
        this.pvz = response.data.cdek_pvz;
        this.active_cdek_pvz = true;
        this.delivery_price = response.data.cdek_answer.total_sum;
        this.delivery_price_cdek = response.data.cdek_answer.total_sum;
        this.generate_all_price();
        //this.cdek_pvz(this.cdek_city_code);

      });

    },

    // DaData
    answer_city_DaData() {
      if (this.order_city.length > 2) {
        Axios.post('https://api.partner.su/dadata/city',
            {
              order_city: this.order_city
            },
            {
              //withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
              }
            }
        ).then((response) => {

          this.dadata_city_answer = response.data.dadata_answer.suggestions;
          if (response.data.dadata_answer != "none") {
            this.activate_dadata_window();
          }

        });
      } else {
        this.active_dadata_window = false;
      }
    },
    async dadata_city_select(city, kladr) {
      this.order_city = city;
      this.dadata_select_city = city;
      this.dadata_kladr_id = kladr;
      this.active_dadata_window = false;

      await Axios.post('https://api.partner.su/dadata/cdek-city',
          {
            kladr_id: this.dadata_kladr_id
          },
          {
            //withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
            }
          }
      ).then((response) => {

        this.cdek_city_code = response.data.dadata_answer.suggestions[0].data.cdek_id;
        //this.activate_dadata_window();

      });

      // Регистрируемся и получаем расчет от CDEK
      await this.cdek_auth();

    },
    // Определение компании
    answer_company_DaData() {
      if (this.order_ul_name.length > 6) {
        Axios.post('https://api.partner.su/dadata/company',
            {
              company_name: this.order_ul_name
            },
            {
              //withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
              }
            }
        ).then((response) => {

          this.dadata_company = response.data.dadata_answer.suggestions;
          if (response.data.dadata_answer != "none") {
            this.activate_dadata_company_window();
          }

        });
      } else {
        this.active_dadata_company_window = false;
      }
    },
    //Выбор компании
    dadata_company_select(name, inn, kpp, ogrn, address) {
      this.order_ul_name = name;
      this.order_ul_inn = inn;
      this.order_ul_kpp = kpp;
      this.order_ul_ogrn = ogrn;
      this.order_ul_address = address;
      this.active_dadata_company_window = false;
    },
    activate_dadata_company_window:function(){
      this.active_dadata_company_window = true;
    },
    activate_dadata_window:function(){
      this.active_dadata_window = true;
    },
    // Оформление заказа
    orderCreate() {
      let receipt = []; // RoboKassa
      let orderProducts = []; // Продукция в заказе

      // Подготовка рецепта номенклатуры для робокассы
      let items = this.$store.state.ShopCart.cart;
      for(let i = 0; i < items.length; i++) {
        let itemObject = {
          name: items[i].product.g_name,
          quantity: items[i].quantity,
          sum: (items[i].product.prices[0].price * items[i].quantity).toFixed(2)
        };
        let itemOrder = {
          id: items[i].product._id,
          name: items[i].product.g_name,
          slug: items[i].product.slug,
          quantity: items[i].quantity,
          img_path: items[i].product.img_path[0],
          product_price: items[i].product.prices[0].price,
          code1c: items[i].product.code,
          sum: (items[i].product.prices[0].price * items[i].quantity).toFixed(2)
        };
        orderProducts.push(itemOrder);
        receipt.push(itemObject);
      }

      // Данные о клиенте и заказе
      let orderData = {
        order_ul_name: this.order_ul_name,
        order_ul_address: this.order_ul_address,
        order_ul_inn: this.order_ul_inn,
        order_ul_kpp: this.order_ul_kpp,
        order_ul_contact: this.order_ul_contact,
        order_ul_mail: this.order_ul_mail,
        order_ul_phone: this.order_ul_phone,
        order_ul_delivery: this.order_ul_delivery,
        order_fio: this.order_fio,
        order_mail: this.order_mail,
        order_phone: this.order_phone,
        order_city: this.order_city,
        order_address: this.order_address,
        order_delivery_type: this.order_delivery_type,
        order_pay_type: this.order_pay_type,
        delivery_price: this.delivery_price,
        delivery_pvz: this.delivery_pvz,
        order_weight: this.order_weight,
        all_price: this.all_price,
        order_comment: this.order_comment,
        order_products: orderProducts,
        order_price: this.$store.getters.cartTotalPrice.toFixed(2)
      };

      console.log(orderData)

      Axios
          .post('https://api.partner.su/shop/create-order/',
              {
                orderData: orderData
              },
              {
                //withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*', // https://api.partner.su/
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                }
              }

          )
          .then((response) => {
            this.activePayWindow = true;
            this.statusUpload = response.data.title;

          });

      /*
      let md5 = require('md5');
      this.robokassa_receipt = JSON.stringify(receipt);

      let out_sum = this.$store.getters.cartTotalPrice.toFixed(2);
      let mrh_login = "newgkpartner"; // идентификатор магазина
      let mrh_pass1 = "v1WafL5aGkCWO2V4J0Xt"; // пароль #1
      let inv_id = "5"; // Номер счета
      //let inv_desc = "description";  // описание заказа
      let strMd5 = mrh_login + ':' + out_sum + ':' + inv_id + ':' + receipt + ':' + mrh_pass1;
      let crc = md5(strMd5);
      // IsTest = 1


        // Генерация GET-запроса
        window.location.href = encodeURI(
            'https://auth.robokassa.ru/Merchant/Index.aspx?'+
            'MrchLogin=' + mrh_login +
            '&OutSum=' + out_sum +
            '&InvId=' + inv_id +
            '&IsTest=1'+
            '&Receipt=' + this.robokassa_receipt +
            '&Desc=' + inv_desc +
            '&SignatureValue=' + crc
        );
      */
    },
    // Методы оформление заказа
    orderDelivery (dataD) {
      this.order_delivery_type = dataD;

      if (dataD === "Самовывоз") {
        //this.cdek_data_delivery = [];
        this.active_cdek_pvz = false;
        this.delivery_price = 0;
        this.all_price = this.full_price;
      }
      if (dataD === "CDEK") {
        //this.cdek_data_delivery = [];
        this.active_cdek_pvz = true;
        this.delivery_price = this.delivery_price_cdek;
        this.generate_all_price();
      }


    },
    orderPayType (dataP) {
      this.order_pay_type = dataP;
    },
    // Методы корзины
    clearOrder() {
      this.$store.dispatch('clearCart');
      this.generate_full_weight();
    },
    removeProductFromCart(product) {
      this.$store.dispatch('removeProductFromCart', product);
      this.generate_full_weight();
    },
    formatPrice(value) {
      let val = value.toFixed(2).replace('.', ',')
      return val; //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    generate_full_weight () {
      let all_weight = 0;
      for(let i = 0; i < this.$store.state.ShopCart.cart.length; i++) {
        // Приводим вес к числу
        let product_weight_str = this.$store.state.ShopCart.cart[i].product.weight.toString();
        let product_weight_float = parseFloat(product_weight_str.replace(/,/, '.').replace(/\s/g, ''));
        let product_weight_g = Math.ceil((product_weight_float * 1000)) * this.$store.state.ShopCart.cart[i].quantity;
        console.log(product_weight_g);
        all_weight = all_weight + product_weight_g

      }
      this.full_weight = all_weight;
    },
    generate_full_price () {
      this.full_price = parseFloat(this.$store.getters.cartTotalPrice.toFixed(2));
      this.all_price = parseFloat(this.$store.getters.cartTotalPrice.toFixed(2));
    },
    generate_all_price () {
     let product_price = this.full_price;
     let delivery = this.delivery_price;
     this.all_price =  product_price + delivery;
    }
  },
  created() {
    this.generate_full_weight();
    this.generate_full_price();
  }
}
</script>

<style scoped>
  .full-price {
    width: 100%;
    padding: 50px 50px 30px 50px;
    border:1px solid #e5e5e5;
    text-align: center;
    border-radius: 5px;
  }
  .cdek-data-pvz {
    display: none;
  }
  .select-pvt {
    padding: 8px;
    border: 1px solid #e5e5e5;
    width: 100%;
    border-radius: 5px;
  }
  .cdek-data-window {
    padding: 8px 20px;
    border-radius: 10px;
    border:1px solid #e5e5e5;
    font-size: 14px;
    font-family: Tahoma;
  }
  .dadata_city_answer {
    width: 100%;
    margin: 5px 0px;
    padding: 0px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    border:1px solid #e5e5e5;
    position: absolute;
    background: #FFFFFF;
    z-index: 7000;
    display: none;
  }
  .dadata_company_answer {
    width: 100%;
    margin: 5px 0px;
    padding: 0px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    border:1px solid #e5e5e5;
    position: absolute;
    background: #FFFFFF;
    z-index: 7000;
    display: none;
  }
  .active {
    display: block;
  }
  .find-city {
    background: #FFFFFF;
    border-bottom: 1px;
    width: 100%;
    position:relative;
  }
  .find-city:hover {
    background: #e5e5e5;
  }
  .find-company {
    background: #FFFFFF;
    border-bottom: 1px;
    width: 100%;
    position:relative;
  }
  .find-company:hover {
    background: #e5e5e5;
  }
  .cart-order-block {
    width: 100%;
    min-height: 300px;
    border: 0px solid #CCCCCC;
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
  }
  .cart-count {
    padding: 6px;
    color: #fff;
    background: #2c3e50;
    border-radius:5px;
    text-align: center;
    margin-bottom: 20px;
  }
  .clearButton {
    width: auto;
    border:0px;
    background: crimson;
    border-radius: 5px;
    padding: 8px;
    color: #ffffff;
  }
  .cart-elm {
    border-bottom:1px solid #e5e5e5;
    margin-bottom: 10px;
    padding: 10px;
  }
  .removeButton {
    background: crimson;
    border-radius: 10px;
    border:0px solid #666;
    color: #ffffff;
    font-weight: bold;
  }
  .removeButton:hover {
    background: #666666;
  }
  .order-block {

  }
  .order-header {

  }
  .order-sub-header {
    font-size: 21px;
  }
  .order-input {
    width: 100%;
    margin:15px 0px;
    border-radius: 5px;
    border:1px solid #e5e5e5;
    padding: 8px;
    font-family: sans-serif;
    font-size: 14px;
  }
  .order-button-delivery-type-1 {
    background: url('~@/assets/img/samovyvoz.png') no-repeat;
    background-size: contain;
    width: 180px;
    height: 80px;
    border:1px solid #e5e5e5;
    border-radius: 10px;
  }
  .order-button-delivery-type-1:hover {
    border:1px solid #00A116;
  }
  .order-button-delivery-type-2 {
    background: url('~@/assets/img/cdek.png') no-repeat;
    background-size: contain;
    width: 180px;
    height: 80px;
    border:1px solid #e5e5e5;
    border-radius: 10px;
  }
  .order-button-delivery-type-2:hover {
    border:1px solid #00A116;
  }
  .order-button-delivery-type-3 {
    background: url('~@/assets/img/transport.png') no-repeat;
    background-size: contain;
    width: 180px;
    height: 80px;
    border:1px solid #e5e5e5;
    border-radius: 10px;
  }
  .order-button-delivery-type-3:hover {
    border:1px solid #00A116;
  }
  .order-button-pay-type-1 {
    background: url('~@/assets/img/oplata.png') no-repeat;
    background-size: contain;
    width: 180px;
    height: 80px;
    border:1px solid #e5e5e5;
    border-radius: 10px;
  }
  .order-button-pay-type-1:hover {
    border:1px solid #00A116;
  }
  .order-button-pay-type-2 {
    background: url('~@/assets/img/schet.png') no-repeat;
    background-size: contain;
    width: 180px;
    height: 80px;
    border:1px solid #e5e5e5;
    border-radius: 10px;
  }
  .order-button-pay-type-2:hover {
    border:1px solid #00A116;
  }
  .order-button-pay-type-3 {
    background: url('~@/assets/img/vmm.png') no-repeat;
    background-size: contain;
    width: 180px;
    height: 80px;
    border:1px solid #e5e5e5;
    border-radius: 10px;
  }
  .order-button-pay-type-3:hover {
    border:1px solid #00A116;
  }
  .order-button-create {
    margin: 25px;
    text-align: center;
    font-family:sans-serif;
    font-weight: normal;
    border:none;
    border-radius: 5px;
    background: green;
    color:#FFF;
    padding: 8px;
    transition: 0.5s;
  }
  .order-button-create:hover {
    margin: 25px;
    text-align: center;
    font-family:sans-serif;
    font-weight: normal;
    border:none;
    border-radius: 5px;
    background: forestgreen;
    color:#FFF;
    padding: 8px;
    transition: 0.5s;
  }
  ::v-deep .card-body {
    padding: 15px 0px;
  }
  ::v-deep .card-header {
    padding: 15px 0px;
    background-color: unset;
    border-bottom: unset;
  }
  ::v-deep .nav-tabs .nav-link {
    border: 1px solid #222222;
    border-radius: 5px;
    color: #222222;
    background: #FFFFFF;
    margin-right: 10px;
  }
  ::v-deep .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #FFFFFF;
    background-color: #00A116;
    border-color: unset;
    border-radius: 5px;
  }
  ::v-deep .card-header-tabs {
    margin-left: unset;
  }
  #success-window-pay {
    display: none;
    position: fixed;
    width: 500px;
    height: 500px;
    top:100px;
    left: 38%;
    padding: 25px;
    text-align: center;
    background-image: linear-gradient(66.62deg,#1f6f15,#3e8b34);
    color:#FFFFFF;
    z-index: 11000;
  }
  #success-window-pay.activePayWindow {
    display: block;
  }
  .thanks-elm {
    width: 100%;
    height: 100px;
    position: relative;
    margin: 20px 0px;
    font-size: 30px;
  }
  .pay-block {
    width: 100%;
    height: 100px;
    position: relative;
  }
  .mail-pass {
    width: 100%;
    height: 50px;
    position: relative;
  }
</style>