<template>
  <div id="ShopCategory">
    <div class="breadcrumbs-container">
      <span><router-link to="/">На главную</router-link></span> /
      <span>Каталог</span> /
      <span v-for="(url, i) in breadcrumbsURL" :key="i">
        <router-link :to="'/catalog/' + url">{{ breadcrumbs[i] }}</router-link> /
      </span>
    </div>
    <div class="category-block fadeIn">
      <div class="container">

        <div class="row">
          <div class="col-12">

          </div>
          <div class="d-none d-sm-block col-sm-3 filter-block">
            <div class="row">
              <div class="col">
                <h3>Фильтры</h3>
              </div>
              <div class="col">
                <button class="rstFilter" @click="reset_filter()">Сбросить фильтры</button>
              </div>
            </div>
            <div class="row">
              <div v-if="filterCheckBox.length > 0" class="col-12">
                <b>Выбранные фильтры:</b>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="offFilter" v-for="filter in filterCheckBox" :key="filter.name">
                  <button class="offFilterButton" v-on:click="offFilter(filter.name, filter.elm, filter.v)">
                    Х {{ filter.name }}: {{ filter.elm }}
                  </button>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-12">
                <b>Фильтр по цене</b><br><br>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <input type="text" class="form-control" v-model="minPriceFilter" :placeholder="minPriceFilterPlaceholder" :input="sortByPriceMin" /><br>
              </div>
              <div class="col-2 text-center"><b>-</b></div>
              <div class="col-5">
                <input type="text" class="form-control" v-model="maxPriceFilter" :placeholder="maxPriceFilterPlaceholder" /><br>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b>Размер</b><br><br>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="text" class="form-control" v-model="searchQuerySize" placeholder="Поиск по размеру:" />
              </div>
            </div>
            <div class="filter-elm">
              <div v-for="attSize in resultQuerySize" :key="attSize.elm" class="row">
                <div class="col-1"><input v-bind:value="'Размер' + ':' + attSize.elm" v-model="checkedFilters" type="checkbox" v-on:click="filterJobs('Размер', attSize.elm, 'Размер' + ':' + attSize.elm)" /></div>
                <div class="col">{{ attSize.elm }} ({{ attSize.quantity }})</div>
              </div>
            </div>
            <!-- Other filters -->
            <div v-for="attribute in attributes"  :key="attribute.name">
              <div v-if="attribute.name != 'Размер' &&
                         attribute.name != '' &&
                         attribute.name != 'Видео' &&
                         attribute.name != 'ОТИС (для отчета)'"
              >
                <div class="row">
                  <div class="col-12">
                    <b>{{ attribute.name }}</b>
                  </div>
                </div>
                <div class="filter-elm">
                  <div v-for="att in attribute.att" :key="att.elm" class="row">
                    <div class="col-1"><input v-bind:value="attribute.name + ':' + att.elm" v-model="checkedFilters" type="checkbox" v-on:click="filterJobs(attribute.name, att.elm, attribute.name + ':' + att.elm)" /></div>
                    <div class="col">{{ att.elm }} ({{att.quantity}})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-9">
            <div class="row">
              <div class="col-12">
                <h1 style="font-size: 21px; font-weight: bold;">{{ thisCategory[0].name }}</h1>
                <br>
              </div>
            </div>
            <div class="sub-categories-container">
              <div class="row" style="margin-right: 0px; margin-left: 0px;">
                <div v-for="sub in subcategory_listing"
                     :key="sub.f_id"
                     class="col-12 col-sm-3"
                     style="padding-left: 5px; padding-right: 5px;"

                >
                  <div class="col-12" style="padding-left: 5px; padding-right: 5px;">
                    <div class="row sub-categories-elm">
                      <div class="col-3" style="padding-left: 5px; padding-right: 5px; text-align: center;">
                        <img style="width:48px; height: auto;" :src="'https://exchange.partner.su/catimg/' + sub.name + '.jpg'" />
                      </div>
                      <div class="col-9" style="padding-left: 5px; padding-right: 5px;">
                        <router-link :to="'/catalog/' + sub.slug">{{ sub.name }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <b>По артикулу:</b><br/>
                <input type="text" class="form-control" v-model="searchQuery" /><br/>
              </div>
              <div class="col-5">
                <b>По наименованию:</b><br/>
                <input type="text" class="form-control" v-model="searchQueryName" /><br/>
              </div>
              <div class="col-2">
                <b>По цене:</b><br>
                <select class="selectSort" v-model="sortByPriceProducts">
                  <option value="">По цене</option>
                  <option value="minPrice">Мин. цена</option>
                  <option value="maxPrice">Макс. цена</option>
                </select>
              </div>
              <div class="col-2">
                <b>Выводить:</b><br>
                <select class="selectSort" v-model="perPage">
                  <option value="24">по 24</option>
                  <option value="36">по 36</option>
                  <option value="48">по 48</option>
                  <option value="96">по 96</option>
                </select>
              </div>
            </div>
            <div class="row product-block">
              <div class="col-6 col-sm-3 product-card" v-for="(product, i) in resultQuery"  :key="product.code">
                <div class="">
                  <span style="color:#666; font-size:12px;">Артикул: {{ product.code }}</span>
                  <div class="product-image-container">
                    <img class="product-image" v-if="product.img_path[0]" v-bind:src="'https://exchange.partner.su/img/' + product.img_path[0]" />
                    <img v-else style="width:100px;" src="https://exchange.partner.su/no_photo.png" />
                  </div>
                  <span style="color:#111; font-size:16px; font-weight: bold;"> {{ product.prices[0].price }} руб.</span><br>
                  <div style="font-weight: normal; font-size:14px; height: 65px;">
                    <router-link
                        class="productLink"
                        :to="{name: 'ShopProduct', params: { slug: product.slug } }"
                    >
                      {{ product.g_name }}
                    </router-link>
                  </div>
                  <span style="color:#666; font-size:12px;">Остаток: {{ product.counts[0].cnt_abs }} шт.</span><br>
                  <div class="row">
                    <div class="col-6">
                      <input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1">
                    </div>
                    <div class="col-6" style="padding: 0px 5px;">
                      <button class="button-buy" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button>
                    </div>
                  </div>
                  <div class="row" style="height: 20px;">
                    <div  class="col-12">
                      <span v-if="quantity[i] > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantity[i]}}шт. на {{ (quantity[i] * product.prices[0].price).toFixed(2) }}р.</span>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="product.features[0].Размер" class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <span class="mini-label">Размер:</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" style="padding-right: 0px;">
                          <img style="width: 24px; margin-right: 4px;" src="/img/ruler.svg" />
                        </div>
                        <div class="col-8">
                        <span style="font-size:12px; font-weight:normal; color:#666;">
                          {{ product.features[0].Размер }}
                        </span>
                        </div>
                      </div>
                    </div>
                    <div v-if="product.features[0].Размер" class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <span class="mini-label">В упаковке:</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" style="padding-right: 0px;">
                          <img style="width: 24px; margin-right: 4px;" src="/img/box.svg" />
                        </div>
                        <div class="col-8">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br><br>
            <div class="clearfix btn-group col-md-2 offset-md-5">
              <button type="button" class="btn btn-sm btn-outline-secondary" v-if="page != 1" @click="page--" > - </button>
              <button type="button" class="btn btn-sm btn-outline-secondary" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              <button type="button" @click="page++" v-if="page < pages.length" class="btn btn-sm btn-outline-secondary"> >> </button>
            </div>
            <br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'ShopCategory',
  props: ['id'],
  data() {
    return {
      page: 1,      // Пагинация текущая страница
      perPage: 24,  // Пагинация кол-во элементов на странице
      pages: [],    // Страницы пагинации
      searchQuery: null,     // Поиск по артикулу среди продукции (на Frontend)
      searchQueryName: null, // Поиск по наименованию среди продукции (на Frontend)
      section: "home",
      categoryname: "",
      products: [],       // Продукция полученная с сервера
      attributes: [],     // Атрибуты полученные с сервера
      subcategories: [],  // Подкатегории полученные с сервера
      subcategory_listing: [], // Листинг подкатегорий
      thisCategory: [['categoryData']],   // Текущая категория полученная с сервера
      endpoint: 'https://api.partner.su/catalog/category/',
      componentKey: "",
      quantity: [],
      subcategories_fid: [],  // Ключи подкатегорий для запроса фильтра на сервер
      filterCheckBox: [],     // Фильтры чекбоксы
      filterToServer: [],     // Фльтры для запроса на сервер
      filterTouch: 0,
      attributeSize: [],      // Отдельный фильтр размера с поиском по фильтру
      searchQuerySize: null,  // Переменная запроса для фильтра по размеру
      attNew:[],              // Фильтры от фильтрованного на сервере товара
      minPriceFilter: null,   // Фильтр по минимальной цене
      maxPriceFilter: null,   // Фильтр по максимальной цене
      minPriceFilterPlaceholder: null, // Плэйсхолдер минимальной цены
      maxPriceFilterPlaceholder: null, // Плэйсхолдер максимальной цены
      sortByPriceProducts: "",  // Сортировка МИН/МАКС цена
      minPrice:null,            // Состояние фильтра мин/макс цена
      checkedFilters: [],
      //checkedFilters2: [],
      checkedName: true,
      breadcrumbs:[],            // Хлебные крошки
      breadcrumbsURL:[]          // Хлебные крошки URL
    }
  },
  components: {
    //Cart
  },
  methods: {

    // Хлебные крошки
    async BreadCrumbs() {

      let path = this.thisCategory[0].path.split(",");
      path.splice(path.length - 1, 1);
      path.splice(0, 1);
      this.breadcrumbs = path;

      await axios.post('https://api.partner.su/shop/slugs',
        {
          slugs: this.breadcrumbs
        },
        {
          //withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
            //'Authorization' : `Bearer ${myToken}`
          }
        }
      ).then((response) => {

        this.breadcrumbsURL = response.data.slugs;

      });

    },
    /*
    isLast (index) {
      return index === this.crumbs.length - 1;
    },
    selected (crumb) {
      this.$emit('selected', crumb);
    },*/

    // Пагинация
    setPages (products) {
      this.pages = [];
      let numberOfPages = Math.ceil(products.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate (products) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);

      return products.slice(from, to);

    },

    // Обработка подкатегорий
    subcategory_list() {

      // Делаем уровни числом
      for (let m = 0; m < this.subcategories.length; m++) {

        this.subcategories[m].level = parseInt(this.subcategories[m].level);

      }

      // Выявляем следующий уровень
      let level = parseInt(this.thisCategory[0].level) + 1;
      //alert(level);

      for (let n = 0; n < this.subcategories.length; n++) {

        if (level === this.subcategories[n].level) {

          this.subcategory_listing.push(this.subcategories[n]);

        }

      }
    },

    // Запрос подкатегорий, продукции и свойств категории
    async fetchCategory(id) {

      //let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        //withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          //'Authorization' : `Bearer ${myToken}`
        }
      }
      await axios(this.endpoint + id, axiosConfig)
          .then(response => {
            // Обнуляем фильтры при переходе в другую категорию
            this.filterCheckBox = [];
            this.filterToServer = [];
            this.subcategories = [];

            // Получаем продукты
            this.products = response.data.fullCategory.products;

            // Подкатегории
            this.subcategories = response.data.fullCategory.categories;

            // Вытаскиваем f_id подкатегорий для передачи в фильтр
            for (let s = 0; s < this.subcategories.length; s++) {
              // Объект для поиска в фильтре
              let subCatFilter = {
                "folder": this.subcategories[s].f_id
              };
              this.subcategories_fid.push(subCatFilter);
            }

            this.attributes = response.data.fullCategory.attributes[0].attributes;

            // Вытаскиваем атрибут размер для фильтра поиска
            for (let z = 0; z < this.attributes.length; z++) {

              if (this.attributes[z].name === "Размер") {

                this.attributeSize = this.attributes[z].att

              }

            }

            // Получаем минимальное и максимальное значение цены
            let allPrices = [];
            for (let p = 0; p < this.products.length; p++) {

              this.products[p].prices[0].price = parseFloat(this.products[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              allPrices.push(this.products[p].prices[0].price);

            }
            // Устанавливаем значения
            this.minPriceFilterPlaceholder = Math.min.apply(Math, allPrices);
            this.maxPriceFilterPlaceholder = Math.max.apply(Math, allPrices);

            this.thisCategory = response.data.thisCategory;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })

        await this.subcategory_list();
        await this.BreadCrumbs();

    },
    // Добавление фильтров
    async filterJobs(name, elm, value) {

      let elmD = 0;
      for(let i = 0; i < this.filterCheckBox.length; i++) {

        if(this.filterCheckBox[i].name === name && this.filterCheckBox[i].elm === elm) {
          elmD = 1;
          this.filterCheckBox.splice(i,1)
        }

      }
      if(elmD === 0) {

        let att = {
          "name": name,
          "elm": elm,
          "v": value
        };
        this.filterCheckBox.push(att);
      }

      this.filterToServer = [];
      this.filterToSrv();

    },
    // Подготовка данных фильтра для сервера
    filterToSrv () {

      for(let i = 0; i < this.filterCheckBox.length; i++){

        let name = "features." + this.filterCheckBox[i].name;
        let elm = this.filterCheckBox[i].elm;
        let custom = {
          [name]: elm
        };
        this.filterToServer.push(custom);

      }
      // Добавляем текущие категории для фильтрации продуктов
      let catObj = {
        '$or': this.subcategories_fid
      };
      this.filterToServer.push(catObj);
      this.filter_apply();

    },
    // Отправка запроса фильтров на сервер
    filter_apply () {

      //let myToken = this.$store.state.userInfo.token;

      axios
          .post('https://api.partner.su/shop/filter',
              this.filterToServer,
              {
                //withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                  //'Authorization' : `Bearer ${myToken}`
                }
              }

          )
          .then((response) => {
            this.products = response.data.products_list;
            this.generateAttribute();

            // Получаем минимальное и максимальное значение цены
            let allPrices = [];
            for (let p = 0; p < this.products.length; p++) {

              this.products[p].prices[0].price = parseFloat(this.products[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              allPrices.push(this.products[p].prices[0].price);

            }
            // Устанавливаем значения
            this.minPriceFilterPlaceholder = Math.min.apply(Math, allPrices);
            this.maxPriceFilterPlaceholder = Math.max.apply(Math, allPrices);

          });
    },

    // offFilter
    offFilter (name, elm, value) {
      //let elmD = 0;
      for(let i = 0; i < this.filterCheckBox.length; i++) {

        if(this.filterCheckBox[i].name === name && this.filterCheckBox[i].elm === elm) {
          //elmD = 1;
          this.filterCheckBox.splice(i,1)
        }

      }
      for (let v; v < this.checkedFilters.length; v++) {
        if(this.checkedFilters[v] === value)
        {
          this.checkedFilters[v].splice(v, 1);
        }
      }
      this.checkedFilters = this.checkedFilters.filter( name => name !== value);


      this.filterToServer = [];
      this.filterToSrv();
    },

    // Генерация атрибутов товара с фильтрацией
    generateAttribute () {

      // Создаем массив всех свойств и элементов из продукции
      let att_full = [];
      for (let i = 0; i < this.products.length; i++) {
        for (const [key, value] of Object.entries(this.products[i].features[0])) {

          att_full.push({
            name: key,
            elm: value
          });

        }
      }

      // Массив всех свойств с массивом под элементы (Cоздаем WORK массив всех свойств с массивом под элементы)
      let att_orig = [];
      for (let m = 0; m < this.products.length; m++) {
        for (const [key] of Object.entries(this.products[m].features[0])) {

          att_orig.push({
            name: key,
            // С пустым значением att
            att: []
          });

        }
      }

      //Удаляем дубликаты атрибутов
      for (var i = 0; i < att_orig.length; i++) {
        for (var j = i + 1; j < att_orig.length;) {
          if (att_orig[i].name === att_orig[j].name) {
            // соответствие по ключу name;
            att_orig.splice(j, 1); // удалить дубликаты объектов;
          } else {
            j++;
          }
        }
      }
      JSON.stringify(att_orig);

      // Надо наполнить массивы в свойствах элементами от НИХ из ПЕРВОНАЧАЛЬНОГО МАССИВА
      // Заполненяем свойствами
      for (let h = 0; h < att_orig.length; h++) {
        for (let k = 0; k < att_full.length; k++) {

          if (att_orig[h].name === att_full[k].name) {

            att_orig[h].att.push({
              elm: att_full[k].elm,
              quantity: 0
            })

          }

        }
      }

      // Удаление дублирующихся элементов свойств
      for (let b = 0; b < att_orig.length; b++) {
        for (let n = 0; n < att_orig[b].att.length; n++) {
          for (let v = n + 1; v < att_orig[b].att.length;) {
            if (att_orig[b].att[n].elm === att_orig[b].att[v].elm) {
              // соответствие по ключу elm;
              att_orig[b].att.splice(v, 1); // удалить дубликаты объектов;
            } else {
              v++;
            }
          }
        }
      }
      JSON.stringify(att_orig);

      // Создаем Quantity c добавлением кол-ва продукции
      for (let a = 0; a < att_orig.length; a++) {
        for (let x = 0; x < att_full.length; x++) {
          if (att_orig[a].name === att_full[x].name) {
            let att = att_orig[a].att;
            for (let index in att) {

              if (att[index].elm === att_full[x].elm) {
                ++att_orig[a].att[index].quantity;
              }

            }
          }
        }
      }
      // Заменяем атрибутами фильтруемых товаров
      this.attributes = att_orig;

      // Если есть атрибут Размера
      // Вытаскиваем атрибут размер для фильтра поиска
      this.attributesSize = [];
      for (let z = 0; z < this.attributes.length; z++) {

        if (this.attributes[z].name === "Размер") {
          this.attributeSize = this.attributes[z].att
        }

      }
    },
    // Reset фильтров
    reset_filter() {
      this.$router.go(0);
    },
    // Добавление в корзину
    addToCart(product, quantity) {

      let quantityValue;

      if(quantity == null) {
        quantityValue = 1;
      } else {
        quantityValue = parseInt(quantity);
      }

      this.$store.dispatch('addProductToCart', {
        product: product,
        quantity: quantityValue,
      });
    },

    // Frontend фильтры
    // Фильтр минимальной цены
    sortByPriceMin (products) {
      return products.filter((item)=> {
        if (this.minPriceFilter === null) {
          this.minPriceFilter = 0;
          return item.prices[0].price >= this.minPriceFilter && item.prices[0].price <= this.maxPriceFilter
        }
        if (this.maxPriceFilter === null || this.maxPriceFilter === "") {
          return item.prices[0].price >= this.minPriceFilter
        }
        if (this.minPriceFilter != null && this.maxPriceFilter != null) {
          return item.prices[0].price >= this.minPriceFilter && item.prices[0].price <= this.maxPriceFilter
        }
      })
    },
    // Фильтр максимальной цены
    sortByPriceMax (products) {
      return products.filter((item)=> {

        if (this.minPriceFilter === null) {
          return item.prices[0].price >= this.minPriceFilterPlaceholder && item.prices[0].price <= this.maxPriceFilter
        }
        if (this.maxPriceFilter === null || this.maxPriceFilter === "") {
          return item.prices[0].price >= this.minPriceFilter && item.prices[0].price <= this.maxPriceFilterPlaceholder
        }
        if (this.minPriceFilter != null && this.maxPriceFilter != null) {
          return item.prices[0].price >= this.minPriceFilter && item.prices[0].price <= this.maxPriceFilter
        }
      })
    },
    // Сортировка по цене
    sortByPriceProductsFunc (products) {
      let temp_products = products;
      return temp_products.sort((a, b) => {

        if (this.sortByPriceProducts === 'maxPrice') {
          return b.prices[0].price - a.prices[0].price;
        } else if (this.sortByPriceProducts === 'minPrice') {
          return a.prices[0].price - b.prices[0].price;
        }
      })
    },
    // Поиск по имени
    searchByNameProducts (products) {
      return products.filter((item) => {
        return this.searchQueryName.toLowerCase().split(' ').every(v => item.g_name.toLowerCase().includes(v))
      })
    },
    // Поиск по артикулу (коду из 1С)
    searchByCodeProducts (products) {
      return products.filter((item)=>{
        return this.searchQuery.toLowerCase().split(' ').every(v => item.code.toLowerCase().includes(v))
      })
    },

    // Скрол к верху страницы
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  computed: {

    resultQuery () {

      let newProducts = this.products;

      // Активация фильтр минимальной цены
      if (this.minPriceFilter) {
        newProducts = this.sortByPriceMin(newProducts);
      }
      // АктивацияФильтр максимальной цены
      if (this.maxPriceFilter) {
        newProducts = this.sortByPriceMax(newProducts);
      }
      // Сортировка продукции по цене
      if (this.sortByPriceProducts === "minPrice" || this.sortByPriceProducts === "maxPrice") {
        newProducts = this.sortByPriceProductsFunc(newProducts)
      }
      // Поиск по имени
      if (this.searchQueryName) {
        newProducts = this.searchByNameProducts(newProducts)
      }
      // Поиск по артикулу
      if(this.searchQuery){
        newProducts = this.searchByCodeProducts(newProducts)
      }
      this.setPages(newProducts);
      //this.scrollToTop();
      return this.paginate(newProducts);
    },
    resultQuerySize () {

      if(this.searchQuerySize){
        return this.attributeSize.filter((item)=>{
          return this.searchQuerySize.toLowerCase().split(' ').every(v => item.elm.toLowerCase().includes(v))
        })
      } else {
        return this.attributeSize;
      }

    },

  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.quantity = [];
        this.subcategory_listing = [];
        this.fetchCategory(this.id);
      }
    },
  },
  created() {

  }
}
</script>

<style scoped>
h5 {
  font-size:16px;
}
.breadcrumbs-container {
  width: 100%;
  height: 30px;
  color: #666666;
  background: none;
  /* padding: 6px 15px; */
  font-weight: normal;
  font-family: Tahoma;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.breadcrumbs-container a {
  color: #111111;
}
.sub-categories-container {
  display: flex;
  align-items: center;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content: left;
  text-align: left;
  margin: 0px -5px 30px -5px;
}
.sub-categories-elm {
  border: 0px solid #e5e5e5;
  margin-bottom: 15px;
}
.sub-categories-elm:hover {
  border:0px solid #cccccc;
}
.sub-categories-elm a {
  color:#111111;
  font-size: 14px;
}
.sub-categories-elm a:hover {
  color:#666666;
  font-size: 14px;
  text-decoration: underline;
}
.product-card {
  height: 400px;
  margin-bottom: 0px;
  padding-right: none;
  padding-left: none;
  border-right: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
}
.product-image-container {
  width:100%;
  height:140px;
  text-align: center;
  position: relative;
}
.product-image {
  max-width: 100%;
  margin:0 auto;
  width:auto;
  max-height: 140px;
}
.mini-label {
  font-weight: bold;
  font-size: 10px;
  font-family: sans-serif;
}
.product-block {
  border-left: 1px solid #F2F2F2;
  border-top: 1px solid #F2F2F2;
  margin: 0px;
}
.category-block {

}
.catQuantity {
  width: 60px;
  text-align: center;
}
.filter-block {
  padding: 0px 15px 0px 0px;
}
.filter-elm {
  height: 150px;
  border: 1px solid #e5e5e5;
  overflow-x: hidden;
  padding: 5px;
  margin: 15px 0px;
  font-size: 14px;
  font-family: sans-serif;
}
.selectSort {
  width: 100%;
  padding: 8px;
  border: 1px solid #CCC;
  border-radius: 5px;
}
.rstFilter {
  border: 0px solid;
  border-radius: 5px;
  font-size: 10px;
  padding: 6px 6px;
  color: #FFF;
  margin: 4px 0px;
  background: green;
}
.card-header {
  background-color: none;
  border-bottom: none;
}
.productLink {
  color:#222222;
  text-decoration: none;
  font-weight: normal;
  font-family: sans-serif;
  font-size: 12px;
}
.button-buy {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 4px;
  letter-spacing: 1px;
  color: #FFF;
  background: #00A100;
  text-transform: uppercase;
  border: 1px solid #00A100;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px 8px;
}
.button-buy:hover {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 3px;
  color: #FFF;
  background: #2dcb00;
  text-transform: uppercase;
  border: 1px solid #2dcb00;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
  padding: 5px 8px;
}
.offFilterButton {
  background-color: #00A116;
  border-radius: 5px;
  padding:3px;
  color:#FFF;
  margin: 5px;
  font-size: 12px;
  font-weight: bold;
  border:none;
}
.block-json-content {
  background:#666;
  width:100%;
  color:#ffc107;
  padding:50px;
  border:1px solid #111111;
}
</style>