<template>
  <div id="AuthUser">
    <form @submit.prevent="submit">
      <img src="https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120" alt=""><br><br>
      <input type="text" required v-model="loginUser" placeholder="Логин"><br><br>
      <input type="password" required v-model="passwordUser" placeholder="Пароль"><br><br>
      <button class="btn btn-lg btn-primary btn-block" type="submit">Войти</button>
      <br><br><br>
      {{ this.$store.state.AuthUser.statusAuth }}
      <br><br><br>
    </form>
  </div>
</template>

<script>

export default {
  name: "AuthUser",
  data() {
    return {
      loginUser: '',
      passwordUser: '',
      token: ''
    }
  },
  methods: {
    authUser() {
      let payload = {
        loginUser: this.loginUser,
        passwordUser: this.passwordUser,
        /* more parameters */
      }
      this.$store.dispatch('login', payload)
    },
    submit() {
      this.authUser();
    },
    logOut() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
  form {
    width:500px;
    height: auto;
    border-radius: 10px;
    padding:25px;
    background:#e5e5e5;
    margin:0 auto;
    text-align:center;
  }
  .userinfoblock {
    border: 1px solid #CCCCCC;
    padding: 25px;
    color: #111;
    text-align: left;
  }
  .logutobutton {
    border:0px;
    text-decoration: underline;
    font-weight: bold;
    color: crimson;
    background: none;
  }
</style>