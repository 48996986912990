<template>
  <div id="home-page">
    <div class="row">
      <!-- Sidebar Links -->
      <div class="col-12 col-sm-3 hpcatblock">
          <div class="hpcategoryblock">
            <div class="row">
              <div class="col-2">
                <img alt="успей купить" src="/img/hp/procent.png">
              </div>
              <div class="col-10 hpcattype">
                <a style="color:#FF0000; font-size:18px;" href="/catalog/krepezh/filter/rasprodazha-is-true/apply/" title="успей купить">Успей купить!</a>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/img/hp/ankery.jpg" alt="анкеры">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/ankery/">Анкеры</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/img/hp/dubeli.jpg" alt="дюбели">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/dyubeli/">Дюбели</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/img/hp/zak.png" alt="заклепки">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/zaklepki/">Заклепки</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="шурупы и саморезы" src="/img/hp/sam.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/shurupy-samorezy/">Шурупы, саморезы</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="нержавеющий крепеж" src="/img/hp/ner.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/nerzhaveyushchiy-krepezh/">Нержавеющий крепеж</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="метрический крепеж" src="/img/hp/metr.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/metricheskiy-krepezh/">Метрический крепеж</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/img/hp/tak.png" alt="такелаж">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/takelazh/">Такелаж</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/img/hp/gvozd.png" alt="гвозди">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/gvozdi/">Гвозди</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/img/hp/himiya.jpg" alt="химические">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/khimicheskie-ankery/">Химические анкеры</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="электромонтажный крепеж" src="/img/hp/elek.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/elektromontazhnyy-krepezh/">Электромонтажный крепеж</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="деревянное домостроение" src="/img/hp/der.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/krepezh-dlya-derevyannogo-domostroeniya/">Деревянное домостроение</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="армирующие материалы" src="/img/hp/arm.png" style="border: 1px solid #e5e5e5; border-radius: 5px;">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/armiruyushchie-materialy/">Армирующие материалы</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="сантехнический крепеж" src="/img/hp/santeh.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/santekhnicheskiy-krepezh/">Сантехнический крепеж</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2" style="padding-top: 15px;">
                <img style="width:32px; height: 32px;" alt="готовые решения Бери и крепи" src="/img/hp/beri-i-krepi.jpg">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/nabory-beri-i-krepi/">Готовые решения<br>"БЕРИ И КРЕПИ"</router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img alt="инструмент, оснастка, ручной инструмент" src="/img/hp/instrument.png">
              </div>
              <div class="col-10 hpcattype">
                <router-link to="/catalog/instrument/">Инструмент</router-link>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-12">
              <router-link to="/catalog/nabory-beri-i-krepi/">
                <img src="/img/banner-bik.jpg" alt="баннер Бери и крепи" title="Готовые решения Бери и крепи">
              </router-link>
              <br><br>
              <router-link to="#">
                <img src="/img/banner_sale.jpg" alt="баннер Распродажа" title="баннер Распродажа sale">
              </router-link>
              <br><br>
            </div>
          </div>
      </div>
      <!-- Products Containers -->
      <div class="col-12 col-sm-9">
        <!-- Слайд Химических анкеров -->
        <div class="sld-hp">
          <router-link to="/catalog/khimicheskie-ankery/">
            <button type="button" class="mainbutton-sldcat">Подробнее</button>
          </router-link>
          <img src="/img/hp/sld.png" style="width: 415px;" title="Химические анкеры ПАРТНЕР" alt="баннер Химические анкеры ПАРТНЕР">
        </div>
        <br>
        <!-- Product TABS -->
        <div class="row">
          <div class="col-12">
            <b-tabs pills card>
              <b-tab title="Анкеры" active><b-card-text>
                <div class="row product-block">
                  <div class="col-6 col-sm-3 product-card" v-for="(product, i) in ankerDataTab"  :key="product.code">
                    <div class="">
                      <span style="color:#666; font-size:12px;">Артикул: {{ product.code }}</span>
                      <div class="product-image-container">
                        <img class="product-image" v-if="product.img_path[0]" v-bind:src="'https://exchange.partner.su/img/' + product.img_path[0]" />
                        <img v-else style="width:100px;" src="https://exchange.partner.su/no_photo.png" />
                      </div>
                      <span style="color:#111; font-size:16px; font-weight: bold;"> {{ product.prices[0].price }} руб.</span><br>
                      <div style="font-weight: normal; font-size:14px; height: 65px;">
                        <router-link
                            class="productLink"
                            :to="{name: 'ShopProduct', params: { slug: product.slug } }"
                        >
                          {{ product.g_name }}
                        </router-link>
                      </div>
                      <span style="color:#666; font-size:12px;">Остаток: {{ product.counts[0].cnt_abs }} шт.</span><br>
                      <div class="row">
                        <div class="col-6">
                          <input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1">
                        </div>
                        <div class="col-6" style="padding: 0px 5px;">
                          <button class="button-buy" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button>
                        </div>
                      </div>
                      <div class="row" style="height: 20px;">
                        <div  class="col-12">
                          <span v-if="quantity[i] > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantity[i]}}шт. на {{ (quantity[i] * product.prices[0].price).toFixed(2) }}р.</span>
                        </div>
                      </div>
                      <div class="row">
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">Размер:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/ruler.svg" />
                            </div>
                            <div class="col-8">
                        <span style="font-size:12px; font-weight:normal; color:#666;">
                          {{ product.features[0].Размер }}
                        </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">В упаковке:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/box.svg" />
                            </div>
                            <div class="col-8">
                              &nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text></b-tab>
              <b-tab title="Дюбели"><b-card-text>
                <div class="row product-block">
                  <div class="col-6 col-sm-3 product-card" v-for="(product, i) in dubelDataTab"  :key="product.code">
                    <div class="">
                      <span style="color:#666; font-size:12px;">Артикул: {{ product.code }}</span>
                      <div class="product-image-container">
                        <img class="product-image" v-if="product.img_path[0]" v-bind:src="'https://exchange.partner.su/img/' + product.img_path[0]" />
                        <img v-else style="width:100px;" src="https://exchange.partner.su/no_photo.png" />
                      </div>
                      <span style="color:#111; font-size:16px; font-weight: bold;"> {{ product.prices[0].price }} руб.</span><br>
                      <div style="font-weight: normal; font-size:14px; height: 65px;">
                        <router-link
                            class="productLink"
                            :to="{name: 'ShopProduct', params: { slug: product.slug } }"
                        >
                          {{ product.g_name }}
                        </router-link>
                      </div>
                      <span style="color:#666; font-size:12px;">Остаток: {{ product.counts[0].cnt_abs }} шт.</span><br>
                      <div class="row">
                        <div class="col-6">
                          <input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1">
                        </div>
                        <div class="col-6" style="padding: 0px 5px;">
                          <button class="button-buy" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button>
                        </div>
                      </div>
                      <div class="row" style="height: 20px;">
                        <div  class="col-12">
                          <span v-if="quantity[i] > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantity[i]}}шт. на {{ (quantity[i] * product.prices[0].price).toFixed(2) }}р.</span>
                        </div>
                      </div>
                      <div class="row">
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">Размер:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/ruler.svg" />
                            </div>
                            <div class="col-8">
                        <span style="font-size:12px; font-weight:normal; color:#666;">
                          {{ product.features[0].Размер }}
                        </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">В упаковке:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/box.svg" />
                            </div>
                            <div class="col-8">
                              &nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text></b-tab>
              <b-tab title="Шурупы и саморезы"><b-card-text>
                <div class="row product-block">
                  <div class="col-6 col-sm-3 product-card" v-for="(product, i) in shurupDataTab"  :key="product.code">
                    <div class="">
                      <span style="color:#666; font-size:12px;">Артикул: {{ product.code }}</span>
                      <div class="product-image-container">
                        <img class="product-image" v-if="product.img_path[0]" v-bind:src="'https://exchange.partner.su/img/' + product.img_path[0]" />
                        <img v-else style="width:100px;" src="https://exchange.partner.su/no_photo.png" />
                      </div>
                      <span style="color:#111; font-size:16px; font-weight: bold;"> {{ product.prices[0].price }} руб.</span><br>
                      <div style="font-weight: normal; font-size:14px; height: 65px;">
                        <router-link
                            class="productLink"
                            :to="{name: 'ShopProduct', params: { slug: product.slug } }"
                        >
                          {{ product.g_name }}
                        </router-link>
                      </div>
                      <span style="color:#666; font-size:12px;">Остаток: {{ product.counts[0].cnt_abs }} шт.</span><br>
                      <div class="row">
                        <div class="col-6">
                          <input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1">
                        </div>
                        <div class="col-6" style="padding: 0px 5px;">
                          <button class="button-buy" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button>
                        </div>
                      </div>
                      <div class="row" style="height: 20px;">
                        <div  class="col-12">
                          <span v-if="quantity[i] > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantity[i]}}шт. на {{ (quantity[i] * product.prices[0].price).toFixed(2) }}р.</span>
                        </div>
                      </div>
                      <div class="row">
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">Размер:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/ruler.svg" />
                            </div>
                            <div class="col-8">
                        <span style="font-size:12px; font-weight:normal; color:#666;">
                          {{ product.features[0].Размер }}
                        </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">В упаковке:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/box.svg" />
                            </div>
                            <div class="col-8">
                              &nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text></b-tab>
              <b-tab title="Такелаж"><b-card-text>
                <div class="row product-block">
                  <div class="col-6 col-sm-3 product-card" v-for="(product, i) in takelazhDataTab"  :key="product.code">
                    <div class="">
                      <span style="color:#666; font-size:12px;">Артикул: {{ product.code }}</span>
                      <div class="product-image-container">
                        <img class="product-image" v-if="product.img_path[0]" v-bind:src="'https://exchange.partner.su/img/' + product.img_path[0]" />
                        <img v-else style="width:100px;" src="https://exchange.partner.su/no_photo.png" />
                      </div>
                      <span style="color:#111; font-size:16px; font-weight: bold;"> {{ product.prices[0].price }} руб.</span><br>
                      <div style="font-weight: normal; font-size:14px; height: 65px;">
                        <router-link
                            class="productLink"
                            :to="{name: 'ShopProduct', params: { slug: product.slug } }"
                        >
                          {{ product.g_name }}
                        </router-link>
                      </div>
                      <span style="color:#666; font-size:12px;">Остаток: {{ product.counts[0].cnt_abs }} шт.</span><br>
                      <div class="row">
                        <div class="col-6">
                          <input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1">
                        </div>
                        <div class="col-6" style="padding: 0px 5px;">
                          <button class="button-buy" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button>
                        </div>
                      </div>
                      <div class="row" style="height: 20px;">
                        <div  class="col-12">
                          <span v-if="quantity[i] > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantity[i]}}шт. на {{ (quantity[i] * product.prices[0].price).toFixed(2) }}р.</span>
                        </div>
                      </div>
                      <div class="row">
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">Размер:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/ruler.svg" />
                            </div>
                            <div class="col-8">
                        <span style="font-size:12px; font-weight:normal; color:#666;">
                          {{ product.features[0].Размер }}
                        </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="product.features[0].Размер" class="col-6">
                          <div class="row">
                            <div class="col-12">
                              <span class="mini-label">В упаковке:</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4" style="padding-right: 0px;">
                              <img style="width: 24px; margin-right: 4px;" src="/img/box.svg" />
                            </div>
                            <div class="col-8">
                              &nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text></b-tab>
            </b-tabs>
          </div>
        </div>
        <br>
        <!-- Готовые решение -->
        <div class="row">
          <div class="col-12">
            <h3 style="font-size: 21px;font-weight: 600;">Готовые решения</h3>
            <p>
              Новая линейка крепежей под брендом «БЕРИ И КРЕПИ». В линейке 25 наборов,
              которые перекрывают весь спектр бытовых потребностей крепления домашней утвари
            </p>
          </div>
          <div class="col-12">
            <div class="row product-block">
              <div class="col-6 col-sm-3 product-card" v-for="(product, i) in beryKrepyDataTab"  :key="product.code">
                <div class="">
                  <span style="color:#666; font-size:12px;">Артикул: {{ product.code }}</span>
                  <div class="product-image-container">
                    <img class="product-image" v-if="product.img_path[0]" v-bind:src="'https://exchange.partner.su/img/' + product.img_path[0]" />
                    <img v-else style="width:100px;" src="https://exchange.partner.su/no_photo.png" />
                  </div>
                  <span style="color:#111; font-size:16px; font-weight: bold;"> {{ product.prices[0].price }} руб.</span><br>
                  <div style="font-weight: normal; font-size:14px; height: 65px;">
                    <router-link
                        class="productLink"
                        :to="{name: 'ShopProduct', params: { slug: product.slug } }"
                    >
                      {{ product.g_name }}
                    </router-link>
                  </div>
                  <span style="color:#666; font-size:12px;">Остаток: {{ product.counts[0].cnt_abs }} шт.</span><br>
                  <div class="row">
                    <div class="col-6">
                      <input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1">
                    </div>
                    <div class="col-6" style="padding: 0px 5px;">
                      <button class="button-buy" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button>
                    </div>
                  </div>
                  <div class="row" style="height: 20px;">
                    <div  class="col-12">
                      <span v-if="quantity[i] > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantity[i]}}шт. на {{ (quantity[i] * product.prices[0].price).toFixed(2) }}р.</span>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="product.features[0].Размер" class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <span class="mini-label">Размер:</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" style="padding-right: 0px;">
                          <img style="width: 24px; margin-right: 4px;" src="/img/ruler.svg" />
                        </div>
                        <div class="col-8">
                        <span style="font-size:12px; font-weight:normal; color:#666;">
                          {{ product.features[0].Размер }}
                        </span>
                        </div>
                      </div>
                    </div>
                    <div v-if="product.features[0].Размер" class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <span class="mini-label">В упаковке:</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" style="padding-right: 0px;">
                          <img style="width: 24px; margin-right: 4px;" src="/img/box.svg" />
                        </div>
                        <div class="col-8">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <!-- Наши проекты -->
        <div class="row">
          <div class="col-12">
            <h3 style="font-size: 21px;font-weight: 600;">Наши проекты</h3>
            <p>
              Опыт реализации решений любого масштаба
            </p>
            <div class="row">
              <div class="col-12 col-sm-4" v-for="page in projects" :key="page._id">
                <div class="blog-card">
                  <div class="blog-card-img-container">
                    <img :src="'https://api.partner.su' + page.anonsImage" />
                  </div>
                  <div class="page-header">
                    <router-link
                        class="news-link"
                        :to="{name: 'ProjectsItem', params: { id: page.slugPage } }"
                    >
                      {{ page.headerPage }}
                    </router-link>
                  </div>
                  <div class="anons-page">
                    {{ page.anonsPage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- HomePage Text -->
    <div class="row">
      <div class="col-12">
        <h1>Крепеж и метизы оптом от производителя</h1>
        <div class="home-page-text">
          <p>
            Группа компаний ПАРТНЕР развивается в сфере производства и продажи крепежа и метизной продукции. Полное погружение в тему, наличие производства и испытательной лаборатории позволяет предлагать изделия высокого класса. На 2021 год ГК ПАРТНЕР – один из крупнейших оптовых поставщиков крепежа в России, с которым сотрудничают застройщики, федеральные и региональные сети магазинов, промышленные предприятия, бренды с мировым именем. Мы не просто поставляем крепеж оптом, но и берем на себя полное снабжение проектов. Среди уже реализованных - такие масштабные объекты как Экспофорум, арена «Зенит», Лахта Центр, новая сцена Мариинского театра, аэропорт Пулково-2, и др.
          </p>
          <p>
            Подготовка профессионалов в области крепежных изделий - одно из направлений работы компании. Академия крепежа - это наш учебный центр. Проводим обучающие семинары по продукции, интересующей именно вашу компанию. Мы делимся информацией о том, в чем разбираемся, повышая квалификацию собственных сотрудников и персонала наших партнеров. Ежегодно обучаем до 1500 специалистов.
          </p>
          <p>
            Продукция ГК «Партнер» представлена оффлайн и онлайн, благодаря чему вы можете купить ее в Санкт-Петербурге, в Москве, с доставкой по всей России. Продукция представлена в 60 регионах страны и каждым годом мы становимся ещё ближе к покупателям. Вы можете купить болты, гайки, шайбы, анкеры, а также дюбели, гвозди, саморезы и многое другое по адекватным ценам.
          </p>
          <p>
            В нашем интернет-магазине можно купить крепеж для бытовой и строительной задачи. Каталог включает более 25 000 артикулов, в том числе электромонтажный, перфорированный, метрический, нержавеющий крепеж. Широта ассортимента – это то, чем мы гордимся.
          </p>
        </div>
      </div>
    </div>
    <!-- News Block -->
    <div class="row">
      <div class="col-12">
        <h3 style="font-size: 21px;font-weight: 600;">Новости компании</h3>
        <p>«Создавая безопасность в деталях, мы делаем мир крепче»</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-3" v-for="page in news" :key="page._id">
        <div class="blog-card">
          <div class="blog-card-img-container">
            <img :src="'https://api.partner.su' + page.anonsImage" />
          </div>
          <div class="page-header">
            <router-link
                class="news-link"
                :to="{name: 'NewsItem', params: { id: page.slugPage } }"
            >
              {{ page.headerPage }}
            </router-link>
          </div>
          <div class="anons-page">
            {{ page.anonsPage }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- Promise Block -->
    <div class="row">
      <div class="col-12 col-sm-4 text-center">
        <img style="width:250px; height: auto;" src="/img/25000.svg"/>
      </div>
      <div class="col-12 col-sm-4 text-center">
        <img style="width:250px; height: auto;" src="/img/6000.svg"/>
      </div>
      <div class="col-12 col-sm-4 text-center">
        <img style="width:250px; height: auto;" src="/img/20000.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "home_page",
  data() {
    return {
      news:[],
      projects:[],
      ankerDataTab: [],
      ankerCodes: [
          "00-00073822",
          "00-00074392",
          "00-00080322",
          "00-00079521"
      ],
      dubelDataTab: [],
      dubelCodes: [
          "00-00077208",
          "00-00024025",
          "00-00024722",
          "00-00024023"
      ],
      shurupDataTab: [],
      shurupCodes: [
        "00-00027636",
        "00-00002154",
        "00-00018630",
        "00-00011651"
      ],
      takelazhDataTab: [],
      takelazhCodes: [
        "00-00001252",
        "00-00022730",
        "00-00013666",
        "00-00010856"
      ],
      beryKrepyDataTab: [],
      beryKrepyCodes: [
        "00-00070834",
        "00-00071624",
        "00-00070828",
        "00-00070822"
      ],
      quantity: [],
    }
  },
  methods: {

    async getLastNews() {
        axios.get('https://api.partner.su/site/last-news')
            .then(response => {

              this.news = response.data.news_list;

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
      },
      async getLastProjects() {
        axios.get('https://api.partner.su/site/last-projects')
            .then(response => {

              this.projects = response.data.projects_list;

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
      },
      async getAnkersByCode() {
        axios.post('https://api.partner.su/shop/products-by-code',
                {
                  code1c: this.ankerCodes
                }
            )
            .then(response => {

              console.log(response.data.shop_product_list)

              this.ankerDataTab = response.data.shop_product_list;
              for (let p = 0; p < this.ankerDataTab.length; p++) {
                this.ankerDataTab[p].prices[0].price = parseFloat(this.ankerDataTab[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              }

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            });
      },
      async getDubelsByCode() {
        axios.post('https://api.partner.su/shop/products-by-code',
            {
              code1c: this.dubelCodes
            }
        )
            .then(response => {

              console.log(response.data.shop_product_list)

              this.dubelDataTab = response.data.shop_product_list;
              for (let p = 0; p < this.dubelDataTab.length; p++) {
                this.dubelDataTab[p].prices[0].price = parseFloat(this.dubelDataTab[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              }

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            });
      },
      async getShurupByCode() {
        axios.post('https://api.partner.su/shop/products-by-code',
            {
              code1c: this.shurupCodes
            }
        )
            .then(response => {

              console.log(response.data.shop_product_list)

              this.shurupDataTab = response.data.shop_product_list;
              for (let p = 0; p < this.shurupDataTab.length; p++) {
                this.shurupDataTab[p].prices[0].price = parseFloat(this.shurupDataTab[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              }

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            });
      },
      async getTakelazhByCode() {
        axios.post('https://api.partner.su/shop/products-by-code',
            {
              code1c: this.takelazhCodes
            }
        )
            .then(response => {

              console.log(response.data.shop_product_list)

              this.takelazhDataTab = response.data.shop_product_list;
              for (let p = 0; p < this.takelazhDataTab.length; p++) {
                this.takelazhDataTab[p].prices[0].price = parseFloat(this.takelazhDataTab[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              }

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            });
      },
      async getBeryKrepyByCode() {
        axios.post('https://api.partner.su/shop/products-by-code',
            {
              code1c: this.beryKrepyCodes
            }
        )
            .then(response => {

              console.log(response.data.shop_product_list)

              this.beryKrepyDataTab = response.data.shop_product_list;
              for (let p = 0; p < this.beryKrepyDataTab.length; p++) {
                this.beryKrepyDataTab[p].prices[0].price = parseFloat(this.beryKrepyDataTab[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              }

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            });
      },
      getTopProducts () {
        this.getAnkersByCode();
        this.getDubelsByCode();
        this.getShurupByCode();
        this.getTakelazhByCode();
        this.getBeryKrepyByCode();
      },
      // Добавление в корзину
      addToCart(product, quantity) {

        let quantityValue;

        if(quantity == null) {
          quantityValue = 1;
        } else {
          quantityValue = parseInt(quantity);
        }

        this.$store.dispatch('addProductToCart', {
          product: product,
          quantity: quantityValue,
        });
      },
  },
  created() {
    this.getLastNews();
    this.getLastProjects();
    this.getTopProducts();
  },
}
</script>

<style scoped>
h1, .h1 {
  font-size: 24px;
  font-weight: bold;
  font-family: Microsoft Sans serif;
}
.home-page-text {
  font-size: 14px;
  font-family: Tahoma;
}
.hpcatblock {

}
.hpcategoryblock {
  width: 100%;
  overflow: hidden;
  padding: 0px 0px;
}
.hpcategoryblock img {
  margin:5px;
}
.hpcategoryblock a {
  font-size: 14px;
  color:#222;
}
.hpcattype {
  font-size: 15px;
  padding: 10px !important;
  border-bottom: 1px solid #CCC;
}
.sld-hp {
  width: 100%;
  height: 370px;
  background: url(~@/assets/img/sld.svg);
  position: relative;
  background-size: cover;
  text-align: right;
}
.mainbutton-sldcat {
  display: inline-block;
  color: white;
  font-weight: 400;
  text-decoration: none;
  user-select: none;
  padding: 0.5em 2em;
  outline: none;
  border: 1px solid;
  border-radius: 0px;
  transition: 0.4s;
  position: absolute;
  left: 80px;
  top: 300px;
  background: none;
}
.blog-card {
  width: 100%;
  position: relative;
  border:1px solid #e5e5e5;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: -5px 5px 25px 0 rgb(0 0 0 / 8%);
  height: 450px;
}
.blog-card-img-container {
  width:100%;
  height: 160px;
  overflow: hidden;
  position: relative;
}
.blog-card img {
  width: 100%;
  height: auto;
}
.anons-page {
  font-size: 14px;
  font-family:sans-serif;
  padding: 0px 20px;
}
.page-header {
  font-size: 14px;
  font-weight: 600;
  padding: 20px 20px;
}
.news-link {
  color:#222;
  text-decoration: none;
}
.news-link:hover {
  color: #666;
}
/* TOP Category ELMS*/
.product-block {
  border-left: 1px solid #F2F2F2;
  border-top: 1px solid #F2F2F2;
  margin: 0px;
}
.product-card {
  height: 400px;
  margin-bottom: 0px;
  padding-right: none;
  padding-left: none;
  border-right: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
}
.product-card .col-3, .product-card .col-sm-3 {
  padding-right: 0px;
}
.productLink {
  color:#222222;
  text-decoration: none;
  font-weight: normal;
  font-family: sans-serif;
  font-size: 12px;
}
.button-buy {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 4px;
  letter-spacing: 1px;
  color: #FFF;
  background: #00A100;
  text-transform: uppercase;
  border: 1px solid #00A100;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px 8px;
}
.button-buy:hover {
  font-family: Tahoma;
  font-size: 12px;
  border-radius: 3px;
  color: #FFF;
  background: #2dcb00;
  text-transform: uppercase;
  border: 1px solid #2dcb00;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
  padding: 5px 8px;
}
.product-image-container {
  width:100%;
  height:140px;
  text-align: center;
  position: relative;
}
.product-image {
  max-width: 100%;
  margin:0 auto;
  width:auto;
  max-height: 140px;
}
.mini-label {
  font-weight: bold;
  font-size: 10px;
  font-family: sans-serif;
}
.catQuantity {
  width: 60px;
  text-align: center;
}
/* TABS TOP */
::v-deep .tabs {
  overflow:hidden;
}
::v-deep .card-header-pills {
  margin-right: 0px;
  margin-left: 0px;
}
::v-deep .nav-pills .nav-link.active, ::v-deep.nav-pills .show > .nav-link {
  color: #222;
  background-color: #FFF;
}
::v-deep .nav-pills .nav-link {
  border-radius: 0px;
}
::v-deep .nav-item {
  width:25%;
}
::v-deep .nav-link {
  background: #f2f2f2;
  color: #222222;
  display: block;
  padding: 6px 0px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
::v-deep .card-header {
  padding: 0px 0px;
  margin-bottom: 0;
  background-color: unset;
  border-left: 1px solid #e5e5e5;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
::v-deep .tab-content > .active {
  display: block;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
</style>