// Vue and modules
import Vue from 'vue';
import Router from 'vue-router';
import Axios from 'axios';
import {store} from './store/';

// App Components
import App from './App.vue';

// Home page Components
import HomePage from './components/home_page.vue';

// Auth user
import AuthUser from './components/auth';

// ShopCart
import ShopCart from './components/cart';

// Product catalog
import ShopCategory from './components/category';
import ShopProduct from './components/product';

// News
import NewsBlog from "./components/blog/News";
import NewsItem from "./components/blog/NewsItem";
// Projects
import ProjectsBlog from "./components/blog/Projects";
import ProjectsItem from "./components/blog/ProjectsItem";
// Academy
import AcademyBlog from "@/components/blog/Academy";
import AcademyItem from "@/components/blog/AcademyItem";


// Search Page
import SearchPage from "./components/searchPage";

// User Profile (cabinet)
import  UserProfile from "./components/lk";

// Menu Editor
//import menu_editor from "./components/menu_editor";

// Pages
//import Pages from './components/pages';
// Category Pages
//import category_pages from "./components/category_pages";

// Orders
//import orders from "./components/orders";
//import create_order from "./components/create_order";
//import edit_order from "./components/edit_order";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
//import 'animate.css';
Vue.use(BootstrapVue)
Vue.use(Router);
Vue.use(Axios);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name:'HomePage',
      component: HomePage,
      props: true,
    },
    {
      path: '/novosti',
      name:'NewsBlog',
      component: NewsBlog,
      props: true,
    },
    {
      path: '/novosti/:id',
      name:'NewsItem',
      component: NewsItem,
      props: true,
    },
    {
      path: '/akademiya-krepezha',
      name:'AcademyBlog',
      component: AcademyBlog,
      props: true,
    },
    {
      path: '/akademiya-krepezha/:id',
      name:'AcademyItem',
      component: AcademyItem,
      props: true,
    },
    {
      path: '/kompaniya/proekty',
      name:'ProjectsBlog',
      component: ProjectsBlog,
      props: true,
    },
    {
      path: '/kompaniya/proekty/:id',
      name:'ProjectsItem',
      component: ProjectsItem,
      props: true,
    },
    {
      path: '/auth',
      name:'AuthUser',
      component: AuthUser,
      props: true,
    },
    {
      path: '/cart',
      name:'ShopCart',
      component: ShopCart,
      props: true,
    },
    {
      path: '/catalog',
      name:'App',
      component: { App },
      props: true,
    },
    {
      path: '/catalog/:id',
      name:'ShopCategory',
      component: ShopCategory,
      props: true,
    },
    {
      path: '/products/:slug',
      name:'ShopProduct',
      component: ShopProduct,
      props: true,
    },
    {
      path: '/search/:id',
      name:'SearchPage',
      component: SearchPage,
      props: true,
    },
    {
      path: '/cabinet',
      name:'UserProfile',
      component: UserProfile ,
      props: true,
    },
  ]
})

Vue.config.productionTip = false

window.onload = function () {
  new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
  }).$mount('#app')
}