<template>
  <div id="NewsBlog">
    <div class="row">
      <div class="col-12">
        <h1>Новости</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-3" v-for="page in pagesData" :key="page._id">
        <div class="blog-card">
          <div class="blog-card-img-container">
            <img :src="'https://api.partner.su' + page.anonsImage" />
          </div>
          <div class="page-header">
            <router-link
                class="news-link"
                :to="{name: 'NewsItem', params: { id: page.slugPage } }"
            >
              {{ page.headerPage }}
            </router-link>
          </div>
          <div class="anons-page">
            {{ page.anonsPage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewsBlog",
  data() {
    return {
      pagesData: null,
    }
  },
  components: {

  },
  methods: {

    // Запрос страниц новостей (перевести в запрос заголовков, анонсов и картинок)
    async fetchPageCategory() {
      axios.get('https://api.partner.su/site/pages-in-category',
          {
            params:{
              id: "Новости"
            }
          }

      )
          .then(response => {

            this.pagesData = response.data.pages_list;

          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
    },

    scrollToTop() {
      window.scrollTo(0,0);
    },

    // Пагинация
    setPages (products) {
      this.pages = [];
      let numberOfPages = Math.ceil(products.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate (products) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);

      return products.slice(from, to);

    },
  },
  created() {
    this.scrollToTop();
    this.fetchPageCategory();
  }
}
</script>

<style scoped>
  #NewsBlog h1 {
    font-size:2em;
    font-weight: bold;
    font-family: Microsoft Sans Serif;
  }
  .blog-card {
    width: 100%;
    position: relative;
    border:1px solid #e5e5e5;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: -5px 5px 25px 0 rgb(0 0 0 / 8%);
    height: 450px;
  }
  .blog-card-img-container {
    width:100%;
    height: 160px;
    overflow: hidden;
    position: relative;
  }
  .blog-card img {
    width: 100%;
    height: auto;
  }
  .anons-page {
    font-size: 14px;
    font-family:sans-serif;
    padding: 0px 20px;
  }
  .page-header {
    font-size: 14px;
    font-weight: 600;
    padding: 20px 20px;
  }
  .news-link {
    color:#222;
    text-decoration: none;
  }
  .news-link:hover {
    color: #666;
  }

</style>