<template>
  <div id="app">
    <!-- Header Top panel -->
    <div class="top-panel">
      <div class="container">
        <div class="top-menu-row">
          <div class="top-menu-col" style="width:15%; text-align: left;">
            <img style="width: 14px; height: 14px;" src="/img/adress_header.svg" />
            &nbsp;&nbsp;<span  style="font-size:14px;">{{ userCity.location.value }}</span>
          </div>
          <div class="top-menu-col">
            <div class="menu-row-top">
              <div class="menu-col-top">
                <router-link class="menu-list-top" to="#">
                  О компании <img src="/img/arrow.svg" />
                </router-link>
                <div class="menu-list-elm-top">
                  <router-link class="menu-list-elm-a-top" to="/kompaniya/">
                    История компании
                  </router-link><br>
                  <router-link class="menu-list-elm-a-top" to="/novosti">
                    Производство
                  </router-link><br>
                  <router-link class="menu-list-elm-a-top" to="/kompaniya/proekty/">
                    Проекты с нашим крепежом
                  </router-link><br>
                  <router-link class="menu-list-elm-a-top" to="#">
                    Корпоративная айдентика
                  </router-link><br>
                </div>
              </div>
              <div class="menu-col-top">
                <router-link class="menu-list-top" to="#">
                  Карьера <img src="/img/arrow.svg" />
                </router-link>
                <div class="menu-list-elm-top">
                  <router-link class="menu-list-elm-a-top" to="#">
                    Работа в компании
                  </router-link><br>
                  <router-link class="menu-list-elm-a-top" to="#">
                    Вакансии
                  </router-link><br>
                  <router-link class="menu-list-elm-a-top" to="#">
                    Охрана труда
                  </router-link><br>
                </div>
              </div>
              <div class="menu-col-top">
                <router-link class="menu-list-top" to="/novosti">
                  Новости
                </router-link>
              </div>
              <div class="menu-col-top">
                <router-link class="menu-list-top" to="/video">
                  Видео
                </router-link>
              </div>
              <div class="menu-col-top">
                <router-link class="menu-list-top" to="/kontakt">
                  Контакты
                </router-link>
              </div>
            </div>
          </div>
          <div class="top-menu-col" style="width: 10%; text-align: right;">
            <span style="font-weight: bold;font-size: 14px;">Telegram</span> <img style="width: 18px; height: 18px;" src="/img/tg3.svg" />
          </div>
          <div class="top-menu-col" style="width: 15%; text-align: right;">
            <span style="font-weight: bold;font-size: 14px;">zapros@partner.su</span>
          </div>
          <div class="top-menu-col" style="width: 15%; text-align: right;">
            <span style="font-weight: bold;font-size: 14px;">8 (800) 505-31-53</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Header panel -->
    <div class="header-panel">
      <div class="container">
        <div class="row">
          <div class="col" style="min-width: 270px;">
            <router-link to="/">
              <img src="https://partner.su/images/Logo_partner_SVG.svg" class="logo-block">
             </router-link>
          </div>
          <div class="col">
            <div class="catalog-button" @click="activeCatalogMenu = !activeCatalogMenu">
                <img v-if="activeCatalogMenu === false" style="width:12px; height: 12px; margin-top: -2px;" src="/img/button_catalog.svg" />
                <img v-if="activeCatalogMenu === true" style="width:12px; height: 12px; margin-top: -2px;" src="/img/button_catalog_x.svg" />
                Каталог продукции
            </div>
          </div>
          <div class="col">
            <SearchProduct />
          </div>
          <div class="col text-right">
            <!--  -->
            <div v-if="this.$store.state.AuthUser.userInfo.length == 0" class="auth-button">
              <router-link to="/auth"><img style="width:30px; height: 30px; margin-right: 5px;" src="/img/lk.svg" />Личный кабинет</router-link>
            </div>
            <div class="auth-info">
            <span v-if="this.$store.state.AuthUser.userInfo != 0">
             <img style="width:30px; height: 30px;" src="/img/lk.svg" />
              <router-link to="/cabinet">
                <b>{{ this.$store.state.AuthUser.userInfo.userData.fl_fio }}{{ this.$store.state.AuthUser.userInfo.userData.ul_name }}</b>
              </router-link>
            </span>
            </div>
          </div>
          <div class="col">
            <div class="cart-block">
              <router-link to="/cart/">
                <button class="cart-button">
                  <div class="cart-row">
                    <div class="cart-label">
                      <img style="width:30px; height:30px; margin-right: 10px;" src="/img/carticon.svg" />
                      <div class="cart-all-position">{{ this.$store.state.ShopCart.cart.length }}</div>
                    </div>
                    <div class="cart-price">
                      {{ this.$store.getters.cartTotalPrice.toFixed(2) }}руб.
                    </div>
                  </div>
                </button>
              </router-link>
              <!--<button class="cart-button" @click="activeCart = !activeCart">Корзина</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom-panel">
      <div class="container">
        <div class="menu-row">
          <div class="menu-col">
            <router-link class="menu-list" to="/akademiya-krepezha/">
              Академия крепежа <img src="/img/arrow.svg" />
            </router-link>
            <div class="menu-list-elm">
              <router-link class="menu-list-elm-a" to="#">
                Обучение крепежу
              </router-link><br>
              <router-link class="menu-list-elm-a" to="#">
                Полезные статьи
              </router-link><br>
              <router-link class="menu-list-elm-a" to="#">
                Каталоги крепежа PDF
              </router-link><br>
            </div>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Испытания крепежа
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Учебный центр
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Решения для фасадов
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Проектирование
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              KAN-BAN
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Покраска крепежа
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Фасовка крепежа
            </router-link>
          </div>
          <div class="menu-col">
            <router-link class="menu-list" to="#">
              Крепеж комплектом
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="catalog-menu-block" :class="{ activeCatalogMenu }">
      <CatalogMenu />
    </div>
    <!-- Content block -->
    <div class="wrapper">
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <router-view />
            </div>
          </div>
          <br><br><br>
        </div>
      </div>
    </div>
    <!-- Footer blocks -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6">
            <span style="font-size: 24pt;">Выгодные предложения </span><br>
            <span style="font-size: 24pt;">каждую неделю</span><br>
            <span style="font-size: 12pt;">Подпишитесь на нашу рассылку</span>
          </div>
          <div class="col-12 col-sm-6">
            <div class="row">
              <input
                  v-model="subscribeData"
                  style="width: 300px; height: 39px; border-radius: 5px;"
                  id="ffinput"
                  class="form-control formcta"
                  placeholder="Ваша почта"
              />
              <button
                  v-on:click="subscribe_button()"
                  style="background: #00D51D; color:#FFF; height:40px; margin-left: 5px; border: 1px solid #02BF1C; border-radius: 4px;"
              >
                Подписаться
              </button>
              <span style="font-size: 8pt;">
                Нажимая кнопку «Подписаться», я даю свое согласие на обработку моих персональных данных,
                в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных»,
                на условиях и для целей, определенных в Согласии на обработку персональных данных
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-link">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-3 footer-item">
            <br>
            <h3>О компании</h3>
            <div>
              <a href="/novosti/">Новости</a>
            </div>
            <div>
              <a href="/video-kontent/">Видео</a>
            </div>
            <div>
              <a href="/kompaniya/" title="История развития">История развития</a>
            </div>
            <div>
              <a href="/kompaniya/proekty/">Проекты с нашим крепежом</a>
            </div>
            <div>
              <a href="/kompaniya/korporativnaya-aydentika/">Корпоративная айдентика</a>
            </div>
            <div>
              <a href="/karera/okhrana-truda/">Охрана труда</a><br>
            </div>
            <div>
              <a href="/dostavka-i-oplata/">Доставка и оплата</a>
            </div>
            <div>
              <a href="/requisites/">Реквизиты</a>
            </div>
            <div>
              <a href="/return/">Политика возврата</a>
            </div>
            <br>
            <h3>Карьера</h3>
            <div>
              <a href="/karera/">Работа в компании</a>
            </div>
            <div>
              <a href="/karera/vakansii/">Вакансии</a>
            </div>
            <br>
          </div>
          <div class="col-12 col-sm-3 footer-item">
            <br>
            <h3>Академия крепежа</h3>
            <div>
              <a href="/akademiya-krepezha/ispytatelnaya-laboratoriya/">Испытательная лаборатория</a>
            </div>
            <div>
              <a href="/akademiya-krepezha/uchebnyy-tsentr/">Учебный центр</a>
            </div>
            <div>
              <a href="/akademiya-krepezha/tekhnicheskaya-dokumentatsiya/">Техническая документация</a>
            </div>
            <div>
              <a href="/akademiya-krepezha/">Образовательные материалы</a>
            </div>
            <br>
          </div>
          <div class="col-12 col-sm-3 footer-item">
            <br>
            <h3>Услуги</h3>
            <div>
              <a href="/dlya-biznesa/">Для бизнеса</a>
            </div>
            <div>
              <a href="/akademiya-krepezha/ispytatelnaya-laboratoriya/">Испытательная лаборатория</a>
            </div>
            <br>
            <br>
            <img style="width:200px; height: 38px;" src="/img/visa.svg">
            <div class="row">
              <div class="col-xs-12">
                <div class="visaiconfooter"></div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 footer-item">
            <br>
            <h3 style="text-decoration:underline;"><a href="/kontakt/">Контакты</a></h3>
            <div class="row">
              <div class="col-2">
                <img style="width: 32px;" alt="адрес" src="/img/adressicon2.svg">
              </div>
              <div class="col-10">
                <b>Санкт-Петербург</b><br>
                196191, ул.Варшавская, д.51, к. 1, лит. А, пом. 16 Н<br>
                тел. <a href="tel:88126124220">+7 (812) 612-42-20</a><br>
                <br>
                <b>Москва</b><br>
                111024, 2-я Кабельная ул.,<br>
                д. 2, стр. №3, оф. 306<br>
                тел. <a href="tel:84959661594"><a href="tel:+74959661594">+7 (495) 966-15-94</a></a><br>
                <br>
                Время работы: <br>пн-пт с 9:00 до 18:00<br>				</div>
            </div>
            <div class="row">
              <div class="col-2">
                <img style="width: 32px;" alt="почта" src="/img/pt.svg">
              </div>
              <div class="col-10">
                <a href="mailto:zapros@partner.su">zapros@partner.su</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-4">
            <br>
            <a style="text-decoration:underline;" href="/antikorruptsionnaya-politika/"><span style="font-size: 9pt;">Антикоррупционная политика</span></a>
            <br>
            <br>
            <a style="text-decoration:underline;" href="/politika-v-otnoshenii-obrabotki-personalnykh-dannykh/">
              <span style="font-size: 9pt;" class="">Политика конфиденциальности</span>
            </a>
            <br>
            <a style="text-decoration:underline;" href="/usloviya-ispolzovaniya-sayta/"><span style="font-size: 9pt;">
              Условия использования сайта </span>
            </a>
            <br>
            <span style="font-size: 9pt;">Информация, указанная на сайте, не является публичной офертой.
              По вопросам, связанным с работой сайта, просьба писать на
            </span><a href="mailto:mail@partner.su">
              <span style="font-size: 9pt;">mail@partner.su</span>
            </a>
            <br>
            <span style="font-size: 9pt;">© ООО «ПАРТНЕР ИНТ» 2022 </span>
            <br>
            <div class="starlogo">
              <br>
              <a target="_blank" href="https://l555.ru"><img alt="star1.png" src="/img/starsoft.png" title="star1.png"></a>
            </div>

          </div>
          <div class="col-12 col-sm-2">
            <p style="text-align: left;">
              <br>
              <span style="font-size: 9pt;">
                Контакты генерального директора компании ПАРТНЕР Иоффе Г.Ф.<br>
                Мне очень важны ваши отзывы и предложения, пожалуйста,
                присылайте их по адресу
                <a href="mailto:gendir@partner.su"><b>gendir@partner.su</b></a>.<br>
                А мы в свою очередь сделаем все, чтобы наш сервис стал ещё лучше!
              </span>
              <br>
              <br>
            </p>
          </div>
          <div class="col-12 col-sm-2">
            <div class="social-icons">
              <div class="row">
                <div class="col-4">
                  <a target="_blank" style="text-decoration:none;" href="https://t.me/partnersu">
                    <div class="telegram-icon-footer"></div>
                  </a>
                </div>
                <div class="col-4">
                  <a target="_blank" style="text-decoration:none;" href="https://vk.com/partnersu">
                    <div class="vk-icon-footer"></div>
                  </a>
                </div>
                <div class="col-4">
                  <a target="_blank" style="text-decoration:none;" href="https://www.youtube.com/channel/UCOuKiAbeylECxzweJi-e9Ug/">
                    <div class="youtube-icon-footer"></div>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <a target="_blank" href="/img/qr-covid.svg" data-lightbox="images">
                  <img
                      alt="QR-код на соответствие Стандартам безопасной деятельности организации"
                      src="/img/qr-covid.svg"
                      title="QR-код на соответствие Стандартам безопасной деятельности организации"
                      width="150"
                      height="150"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <p style="text-align: left;">
              <br>
              <br>
              <br>
              <br>
              <span style="font-size: 9pt;">
                QR-код на соответствие Стандартам безопасной деятельности организации,
                в том числе санитарно-гигиенической безопасности,
                в целях противодействия распространению в Санкт-Петербурге новой коронавирусной инфекции
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>

import axios from "axios"
//import ShopCart from "./components/cart";
import CatalogMenu from "./components/catalogMenu";
import SearchProduct from "./components/search";

export default {
  name: 'App',
  components: {
    //ShopCart
    CatalogMenu,
    SearchProduct
  },
  data() {
    return {
      section: "home",
      btc:[],
      activeCatalogMenu: false,
      activeCart: false,
      subscribeData: "",
      userCity: [],
      userIp:"",
      userHeaders:"",
      testpost: []
     }
  },
  methods: {
    // DaData user city find by IP
    async user_ip () {

      let query = await axios.get('https://api.partner.su/user-ip');
      this.userHeaders = query.data;
      await this.user_city_DaData(query.data.user_ip)
      //await this.user_city_DaData();this.userIp =

    },
    async user_city_DaData(ip_user) {

      //let ip_user = "85.249.21.79";

      let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=";
      let token = "bc2ba95f7f4a751db2f0594f85c2ea8cf9a3a494";

      let options = {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        }
      }

      fetch(url + ip_user.user_ip, options)
          .then(response => response.text())
          .then(result => this.userCity = JSON.parse(result))
          .catch(error => console.log("error", error));
    },

    // Функции для реализации поиска
    async searchProcess () {

    },
    // Подписка на рассылку
    async subscribe_button() {

    },

    // testpost
    async testPost () {

      await axios.post ("https://api.partner.su/1c/orders", {
        key: "12345678abc"
      }).then((response) =>{
        this.testpost =  response.data
      })

    }
  },
  created() {

    this.testPost();
    this.user_ip();
    //this.user_city_DaData();

  }
}

</script>

<style>
#app {
  font-family: Tahoma;
  font-size:14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  overflow-x:hidden;
}
h1, h2, h3, h4, h5, h6 {
  font-family: Microsoft Sans Serif;
}
body {
  text-rendering: optimizeLegibility;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1250px !important;
    width: 1250px !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1250px !important;
    width: 1250px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 1250px !important;
    width: 1250px !important;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 1250px !important;
    width: 1250px !important;
  }
}
img {
  max-width: 100%;
}
.logo-block {
  text-align: left;
  padding: 0px 0px;
  width: 270px;
  background-repeat: no-repeat;
  height: 60px;
  margin-top: 6px;
}
.header-panel {
  background: #ffffff !important;
  border-bottom: 0px solid #CCCCCC;
  padding:10px 50px;
  position: relative;
  z-index: 9999;
  width: 100%;
}
.top-panel {
  width: 100%;
  height: 36px;
  padding: 6px 0px;
  border-bottom: 0px solid #e5e5e5;
}
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 1000px;
  /* margin-top: 81px; */
}
.content {
  margin-top: 0px;
  padding: 25px;
  /*height: 750px;*/
  width: 100%;
}
.top-menu-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.top-menu-col {
  padding-left: 0px;
  padding-right: 0px;
}
.menu-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:10px 0px 0px 0px;
}
.menu-col {
  padding-left: 0px;
  padding-right: 0px;
}
.menu-list {
  font-size: 14px;
  font-weight: normal;
  color:#666666;
}
.menu-list img {
  width:12px;
  height: 12px;
  margin-left: 5px;
}
.menu-list:hover .menu-list-elm {
  display: block;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
}
.menu-list:hover ~ .menu-list-elm {
  display: block;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
}
.menu-list-elm:hover {
  display: block;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
}
.menu-list-elm {
  width: 250px;
  position: absolute;
  display: none;
  background: #FFFFFF;
  padding: 15px;
  margin:-4px 0px;
  border:1px solid #e5e5e5;
  z-index: 201;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
.menu-list-elm a {
  color:#222222;
  font-size: 14px;
}
/*-- TOP MENU ROW--*/
.menu-row-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:0px 0px 0px 0px;
}
.menu-col-top {
  padding-left: 0px;
  padding-right: 15px;
}
.menu-list-top {
  font-size: 14px;
  font-weight: normal;
  color:#666666;
}
.menu-list-top img {
  width:12px;
  height: 12px;
  margin-left: 5px;
}
.menu-list-top:hover .menu-list-elm-top {
  display: block;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
}
.menu-list-top:hover ~ .menu-list-elm-top {
  display: block;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
}
.menu-list-elm-top:hover {
  display: block;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
}
.menu-list-elm-top {
  width: 250px;
  position: absolute;
  display: none;
  background: #FFFFFF;
  padding: 15px;
  margin:-4px 0px;
  border:1px solid #e5e5e5;
  z-index: 12001;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
.menu-list-elm-top a {
  color:#222222;
  font-size: 14px;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.cart-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
}
.cart-label {
  width: 45px;
  height: 32px;
  position: relative;
}
.cart-price {

}
.cart-button {
  border: 0px;
  background: #FFF;
  color: #222;
  border-radius: 0px;
  padding: 2px;
  width: 180px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
.cart-all-position {
  background: #e5e5e5;
  min-width: 21px;
  min-height: 21px;
  border-radius: 50%;
  text-align: center;
  color: #222;
  position: absolute;
  z-index: 205;
  margin: -38px 18px;
  font-weight: bold;
  padding: 2px;
  font-size: 12px;
}
#catalog-menu-block {
  width:100%;
  background: #ffffff;
  height:0px;
  overflow: scroll;
  transform: translate(0%, 0);
  transition: height 200ms linear;
  overflow-x: hidden;
}
#catalog-menu-block a {
  font-family: Tahoma;
  font-size: 13px;
  color:#111111;
}
#catalog-menu-block a:hover {
  color: #FF0034;
}
.hpnavcattype a {
  font-size: 16px !important;
  font-weight: bold;
  padding: 5px;
}
#catalog-menu-block.activeCatalogMenu {
  height: auto;
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
  margin-top: -35px;
  padding-top: 50px;
}
.catalog-button {
  width: 160px;
  padding: 8px 6px;
  margin-top: 18px;
  background: #ED2924;
  border-radius: 5px;
  color: #FFF;
  font-size: 12px;
  text-align: center;
  font-family: Tahoma;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.catalog-button:hover {
  background: #D6221D;
}
.catalog-button a {
  font-family: Helvetica, sans-serif;
  color:#FFF;
  text-decoration: none;
  font-weight: normal;
}
.catalog-button a:hover {
  color: #FFF;
  text-decoration: none;
}
.cart-block {
  margin-top: 20px;
  text-align: right;
}
.auth-button {
  margin-top: 20px;
  font-size: 14px;
}
.auth-button a {
  color:#222;
  text-decoration: underline;
}
.auth-button:hover a {
  text-decoration: none;
}
.auth-info {
  margin-top: 20px;
}
.auth-info a {
  font-size: 14px;
  color:#222;
  text-decoration: underline;
}
.footer-top {
  width: 100%;
  position: relative;
  color:#FFF;
  padding: 50px 0px;
  background: #00A116;
}
.footer-bottom {
  width: 100%;
  position: relative;
  padding:5px;
  background: #E9EDF0;
}
.footer-bottom a {
  font-family: Tahoma;
  font-size: 14px;
  background: none;
  color: #222;
  text-decoration: none;
  padding: 5px 0px;
}
.footer-bottom a:hover {
  text-decoration: underline;
  color: #666;
}
.social-icons {
  padding: 25px 0px;
}
.telegram-icon-footer {
  background: url(~@/assets/img/tg.svg)  no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  transition: 0.3s;
}
.telegram-icon-footer:hover {
  background: url(~@/assets/img/tg2.svg) no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  transition: 0.3s;
}
.vk-icon-footer {
  background: url(~@/assets/img/vk2.svg)  no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  transition: 0.3s;
}
.vk-icon-footer:hover {
  background: url(~@/assets/img/vk.svg) no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  transition: 0.3s;
}
.youtube-icon-footer {
  background: url(~@/assets/img/youtube2.svg)  no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  transition: 0.3s;
}
.youtube-icon-footer:hover {
  background: url(~@/assets/img/youtube.svg) no-repeat;
  background-size: contain;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  transition: 0.3s;
}
.footer-link {
  width: 100%;
  height: auto;
  position: relative;
  padding: 25px 0px;
  font-family: Tahoma;
  font-size: 14px;
}
.footer-item {
  background: none;
  color: #222;
  padding-top: 0px;
  padding-bottom: 0px;
  transition-duration: 0.5s;
}
.footer-item h3 {
  font-size: 16px;
  font-family: Microsoft Sans Serif;
  font-weight: 600;
}
.footer-item a {
  font-family: Tahoma;
  font-size: 14px;
  background: none;
  color: #222;
  text-decoration: none;
  padding: 5px 0px;
}
.footer-item a:hover {
  text-decoration: underline;
  color: #666;
}
.btc-info {
  margin-top:20px;
}
pre {
  color:#ffc107;
}
</style>
