// Cdek Auth

export default {

    state: {

        cdek_info: [],
        cdek_cities: []

    },
    mutations: {

        // Add CDEK auth info
        ADD_CDEK_INFO (state, Data) {

            state.cdek_info.push(Data);

        },
        ADD_CDEK_CITIES (state, Data) {

            state.cdek_cities.push(Data);

        },
        UNSET_CDEK_INFO (state){

            state.cdek_info = [];

        },
        UNSET_CDEK_CITIES (state){

            state.cdek_cities = [];

        },

    },
    actions: {

        add_cdek_auth_info ({ commit }, payload) {

            commit("ADD_CDEK_INFO", payload);

        },
        unset_cdek_auth_info ({ commit }) {

            commit("UNSET_CDEK_INFO");

        },
        add_cdek_cities ({ commit }, payload) {

            commit("ADD_CDEK_CITIES", payload);

        },
        unset_cdek_cities ({ commit }) {

            commit("UNSET_CDEK_CITIES");

        },
    },
    getters: {

    }

}