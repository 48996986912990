import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import AuthUser from './modules/auth';
import ShopCart from "./modules/cart";
import Transport from "./modules/transport";
import Cdek from "./modules/cdek";
import Breadcrumbs from "./modules/breadcrumbs";

Vue.use(Vuex);

export const store = new Vuex.Store({
   modules: {
       AuthUser,
       ShopCart,
       Cdek,
       Transport,
       Breadcrumbs
   },
   plugins: [createPersistedState()],
});