<template>
  <div id="academy-sidebar-right">
    <div class="stati-container">
      <h3>Новые статьи</h3>
      <div class="row" v-for="page in lastPages" :key="page._id">
        <div class="col-4">
          <img :src="'https://api.partner.su' + page.anonsImage" />
        </div>
        <div class="col-8">
          <router-link
              class="academy-link"
              :to="{name: 'AcademyItem', params: { id: page.slugPage } }"
          >
            {{ page.headerPage }}
          </router-link>
          <br>
          <div class="anons-page">
            {{ page.anonsPage }}
          </div>
          <br>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AcademySidebarRight",
  data () {
    return {
      lastPages: [],
    }
  },
  methods : {
    async getLastAcademyPages() {
      axios.get('https://api.partner.su/site/last-academy')
          .then(response => {

            this.lastPages = response.data.academy_list;

          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
    },
  },
  created() {
    this.getLastAcademyPages();
  }
}
</script>

<style scoped>
  .stati-container {

  }
  .stati-container h3 {
    border-bottom: 4px solid #FF0000;
    padding: 10px 0px;
    font-size: 16px;
    font-family: Microsoft Sans Serif;
    font-weight: 600;
  }
  .academy-link {
    color: #00A116;
  }
  .anons-page {
    font-size: 12px;
    font-family: sans-serif;
  }

</style>