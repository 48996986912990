<template>
  <div id="ShopProduct">
    <div class="breadcrumbs-container">
      <span><router-link to="/">На главную</router-link></span> /
      <span>Каталог</span> /
      <span v-for="(url, i) in breadcrumbsURL" :key="i">
        <router-link :to="'/catalog/' + url">{{ breadcrumbs[i] }}</router-link> /
      </span>
      <span> {{ item.g_name }} </span>
    </div>
    <div class="row">
      <div class="col-12">
        <h1>
          {{ item.g_name }}
        </h1>
        </div>
      </div>
    <div class="row">
        <!-- Блок Слайдер - Slider -->
        <div class="col-5">
          <div class="col-12">
            <carousel
                v-bind:value="slideValue"
                ref="carousel"
                :loop="true"
                :per-page="1"
                :scroll-per-page="true"
                :navigation-enabled="true"
                :pagination-enabled="false"
            >
              <slide v-for="(sld, idx) in imgSld" :key="idx">
                <a v-bind:href="sld.big" data-fancybox="gallery">
                  <img class="big-slide" v-bind:src="sld.big" />
                </a>
                  <!--  <div class="big-slide" :style="{ 'background-image': 'url(' + sld.big + ')' }">&nbsp;</div>  -->
              </slide>
            </carousel>
            <!--
              <div class="carousel-nav">
                <span class="nav-info">{{ selectedID + 1 }} of {{ imgSld.length }}</span>
                <button v-on:click.prevent="selectedID = $refs.carousel.getPreviousPage()" class="btn btn-primary btn-sm carousel-nav-prev"><i class="icon icon-arrow-left"></i></button>
                <button v-on:click.prevent="selectedID = $refs.carousel.getNextPage()" class="btn btn-primary btn-sm carousel-nav-next"><i class="icon icon-arrow-right"></i></button>
              </div>
            -->
          </div>
          <br>
          <!-- Thumbnails -->
          <div class="row-thumbs">
            <div v-for="(slide, idx) in imgSld" :key="idx" class="thumb-elm" style="border:1px solid #f2f2f2;">
              <img class="slider-thumbnail-nav" v-bind:src="slide.thumb" v-on:click="selectedID=slide.id">
            </div>
            <div v-if="item.features[0].Видео" class="thumb-elm">
              <a
                  v-bind:href="'https://www.youtube.com/embed/' + item.features[0].Видео"
                  data-fancybox
                  data-type="iframe"
                  data-preload="false"
                  data-width="640"
                  data-height="480"
              >
                <img class="video-img" src="/img/video.png" />
              </a>
            </div>
          </div>
          <!--
          <v-carousel
              :starting-image="2"
              :images="images"
              :auto-slide-interval="1500"
              :show-progress-bar="true"
          ></v-carousel>
          -->
        </div>
        <!-- Attributes - Блок характеристик -->
        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <div class="att-header">
                Характеристики
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-for="(value, name) in item.features[0]" v-bind:key="name">
              <div v-if="name != '' && name != 'Основное свойство' && name != 'Видео'" class="att-row">
                <div class="row">
                  <div class="col-6" style="text-align: left;">
                    {{ name }}:
                  </div>
                  <div class="col-6" style="text-align: right;">
                    {{ value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Buy block - Блок покупки -->
        <div class="col-3">
          <div class="buy-block">
            <div class="row">
              <div class="col-12">
                <div class="product-code">Код товара: {{ item.code }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div v-if="parseFloat(item.counts[0].cnt_abs.replace(/,/, '.').replace(/\s/g, '')) > 0" style="width:100%; font-weight:bold; font-size:16px; color: #00A116;">
                  В наличии
                </div>
                <div v-if="parseFloat(item.counts[0].cnt_abs.replace(/,/, '.').replace(/\s/g, '')) === 0" style="width:100%; font-weight: bold; font-size:16px; color: #222222;">
                  Нет в наличии
                </div>
                <div class="product-price">{{ item.prices[0].price }} ₽</div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="quantity-header">Укажите количество:</p>
                <input class="input-quantity" type="number" v-model="quantityThisProduct" value="1" min="1" placeholder="1">
                <span v-if="quantityThisProduct  > 0" style="color:#666; font-size: 12px; font-weight: bold;">{{ quantityThisProduct }}шт. на {{ (quantityThisProduct * item.prices[0].price).toFixed(2) }}р.</span>
              </div>
              <div class="col-6">
                &nbsp;
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="button-buy" v-on:click="addToCart(item, quantityThisProduct)">
                  <img src="/img/cart3.svg" style="width:30px; filter:invert(100%);">&nbsp; &nbsp;Добавить в корзину
                </button>
              </div>
              <div class="col-6">
                &nbsp;
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="product-weight">Остаток на складе: {{ item.counts[0].cnt_abs }} шт.</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="product-weight">Вес: {{ item.weight }} кг ({{ (item.weight * 1000).toFixed(2) }} г)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Товары из категории этого товара -->
      <div class="row">
        <div class="col-12">
          <h3 style="font-size: 18px; font-weight: bold;border-bottom: 1px solid #CCC; margin: 15px 0px;">Продукты из этой категории</h3>
          <div class="row">
            <div style="width:100%; max-height: 500px; overflow-y: scroll; overflow-x: hidden">
              <table class="table table-striped table-hover" style="font-size: 12px;">
                <thead>
                <tr>
                  <th scope="col">Артикул</th>
                  <th scope="col">Наименование</th>
                  <th scope="col">Размер</th>
                  <th scope="col">Остаток</th>
                  <th scope="col">Цена</th>
                  <th scope="col">Кол-во</th>
                  <th scope="col">Купить</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(product, i) in products"  :key="product.code">
                  <td v-if="product.nelikvid === '1'" style="background: green;color: #FFFFFF">{{ product.code }}</td>
                  <td v-else>{{ product.code }}</td>
                  <td style="width: 215px;">
                    <router-link
                        class="productLink"
                        :to="{name: 'ShopProduct', params: { slug: product.slug, id: product.id } }"
                    >
                      {{ product.g_name }}
                    </router-link>
                  </td>
                  <td>{{ product.features[0].Размер }}</td>
                  <td>{{ product.counts[0].cnt_abs }} шт.</td>
                  <td>{{ product.prices[0].price }} руб.</td>
                  <td><input class="catQuantity" type="number" v-model="quantity[i]" value="1" min="1" placeholder="1"></td>
                  <td style="width: 120px;"><button class="button-buy-cat-block" @click="addToCart(product, quantity[i])">В КОРЗИНУ</button></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- JSON
      <div class="row">
        <div class="col-12">
          <br><br><br>
          <br><br><br>
          <div class="block-json-content">
            <h3>JSON Данные</h3>
            <pre>{{ this.products }}</pre>
          </div>
          <br><br><br>
        </div>
      </div>
      -->

  </div>
</template>

<script>

import Axios from "axios";

import { Carousel, Slide } from 'vue-carousel';


// FancyBox
import { Fancybox } from "@fancyapps/ui";
import axios from "axios";
Fancybox.bind("[data-fancybox]", {

    zoom: false
});

export default {
  name: "ShopProduct",
  props: ['id', 'slug'],
  components: {
    // eslint-disable-next-line
    Fancybox,
    carousel: Carousel,
    slide: Slide,
  },
  data() {
    return {
      test: "1",
      item: [],
      quantityThisProduct: "1",
      quantity: "1",
      products: [],
      quantityS: "",
      endpoint: 'https://api.partner.su/catalog/category-by-folder/',
      imgSld: [],
      selectedID: 0,
      thisCategory: [],
      breadcrumbs:[],            // Хлебные крошки
      breadcrumbsURL:[]          // Хлебные крошки URL
    }
  },
  methods: {

    // Хлебные крошки
    async BreadCrumbs() {

      let path = this.thisCategory[0].path.split(",");
      console.log(path);
      path.splice(path.length - 1, 1);
      path.splice(0, 1);
      this.breadcrumbs = path;

      await axios.post('https://api.partner.su/shop/slugs',
          {
            slugs: this.breadcrumbs
          },
          {
            //withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
              //'Authorization' : `Bearer ${myToken}`
            }
          }
      ).then((response) => {

        this.breadcrumbsURL = response.data.slugs;

      });

    },

    // Загрузить продукт с сервера
    async fetchProduct(slug) {
      //let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        //withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://api.partner.su/',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          //'Authorization': `Bearer ${myToken}`
        }
      };
      await Axios.post('https://api.partner.su/shop/one-product/slug',
              {
                slugProduct: slug
                //idProduct: id
              },
              axiosConfig
          )
          .then((response) => {
            this.imgSld = [];
            this.item = response.data.shop_product_list[0];
            this.item.prices[0].price = parseFloat(this.item.prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
            this.item.weight = parseFloat(this.item.weight.replace(/,/, '.').replace(/\s/g, ''));
            this.fetchCategoryThisProduct(this.item.folder);
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    // Pull для картинок под слайдер
    async makeSldImages() {
      this.selectedID = 0;
      let imgStructure = {};
      let imgS = [];
      for (let r = 0; r < this.item.img_path.length; r++) {
        imgStructure = {
          id: r,
          big: 'https://exchange.partner.su/img/' + this.item.img_path[r],
          thumb: 'https://exchange.partner.su/img/' + this.item.img_path[r],
        };
        //console.log(imgStructure);
        imgS.push(imgStructure);
      }
      this.images = imgS;
      this.imgSld = imgS;
    },
    // Загрузить продукты из категории этого продукта
    async fetchCategoryThisProduct(id) {

      //let myToken = this.$store.state.userInfo.token;

      let axiosConfig = {
        //withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          //'Authorization' : `Bearer ${myToken}`
        }
      }
      await Axios(this.endpoint + id, axiosConfig)
          .then(response => {
            // Получаем продукты
            this.thisCategory = response.data.thisCategory;
            this.products = response.data.fullCategory.products;
            this.makeSldImages();
            //console.log(this.item.img_path.length + "Кол-во картинок");
            for (let p = 0; p < this.products.length; p++) {
              this.products[p].prices[0].price = parseFloat(this.products[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
            }
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
      await this.BreadCrumbs();
    },

    // Init
    async initProduct() {
      await this.fetchProduct(this.slug);
      //await this.BreadCrumbs();
    },

    // Добавить товар в корзину
    addToCart(product, quantity) {

      let quantityValue;

      this.quantityS = quantity;

      if(quantity === 1 || quantity > 1) {
        quantityValue = parseInt(quantity);
      } else {
        quantityValue = 1;
      }

      this.$store.dispatch('addProductToCart', {
        product: product,
        quantity: parseInt(quantityValue),
      });
    },

    toSlide: function(n) {
      this.selectedID = n;
      this.$refs.carousel.goToPage(n);
    }

  },
  computed: {
    slideValue: function() {
      return parseInt(this.selectedID, 10);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.quantity = [];
        this.initProduct();
      }
    },
  },
  created() {
    this.initProduct();
  }
}
</script>

<style scoped>
@import "../assets/css/fancybox.css";

  h1 {
    font-size: 21px;
    border-bottom: 1px solid #cccccc;
  }

  .breadcrumbs-container {
    width: 100%;
    height: 30px;
    color: #666666;
    background: none;
    /* padding: 6px 15px; */
    font-weight: normal;
    font-family: Tahoma;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .breadcrumbs-container a {
    color: #111111;
  }

  .slider-big {
    width:100%;
    height: 350px;
  }
  .VueCarousel-slide { text-align: center }
  .big-slide {
    position:relative;
    max-width:100%;
    max-height: 350px;
    text-align: center;
  }
  .slider-img-nav {
    max-width:100%;
    max-height: 350px;
    margin: 0 auto;
  }

  .row-thumbs {
    display: flex;
    width:100%;
    overflow: hidden;
  }
  .slider-thumbnail-nav {
    max-width: 70px;
    max-height: 70px;
  }
  .thumb-elm {
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 5px;
    margin: 5px;
  }
  .video-img {
    width: 70px;
    height: 70px;
    margin: 0px
  }
  .carousel-nav {
    position: relative;
    height:2rem;
    /* background-color: #ababab; */
  }
  .VueCarousel-slide img {
    max-width: 100%;
  }
  .carousel-nav .carousel-nav-prev {
    position: absolute;
    left: 0;
    top:0;
    height: 2rem;
  }
  .carousel-nav .carousel-nav-next {
    position: absolute;
    right: 0;
    top:0;
    height: 2rem;
  }
  .carousel-nav .nav-info {
    text-align: center;
    margin: 0 auto;
    display: block;
    position: relative;
    line-height: 2rem;
  }
  .att-header {
    color: #222222;
    font-weight: bold;
    font-family: sans-serif;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid #e5e5e5;
  }
  .att-row {
    border-bottom: 1px solid #e5e5e5;
    padding: 6px;
    font-family:sans-serif;
    font-size:12px;
  }
  .buy-block {
    padding:10px;
    border:1px solid #e5e5e5;
  }
  .product-code {
    color:#666666;
    font-size:14px;
    font-family: sans-serif;
  }
  .product-price {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0px;
  }
  .quantity-header {
    color:#666666;
    font-size: 12px;
    font-weight: bold;
    margin: 0px 0px;
  }
  .button-buy {
    width: 100%;
    margin: 15px 0px;
    border: 0px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #00A116;
    text-align: center;
    padding: 8px;
  }
  .button-buy:hover {
    background-color: #2dcb00;
  }
  .input-quantity {
    width: 100%;
  }
  .input-quantity:focus {
    border: 2px solid #00A116 !important;
  }
  .product-weight {
    font-weight: normal;
    font-size: 14px;
    color: #666666;
  }
  .button-buy-cat-block {
    width: 100%;
    margin: 0px 0px;
    border: 0px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #00A116;
    text-align: center;
    padding: 4px;
  }
  .button-buy-cat-block:hover {
    background-color: #2dcb00;
  }
  .block-json-content {
    background:#666;
    width:100%;
    color:#ffc107;
    padding:50px;
    border:1px solid #111111;
  }
  pre {
    color:#ffc107;
  }
  /*
      red - #D6221D - green1 - #2dcb00 - green2 - #00A116

      ATRIBUTES TABLE DOTTED
      .att-line {
        color: #222222;
        padding: 5px;

      }
      .att-table {
        width: 100%;
      }
      .att-table-name {
        color:#666;
        width: 50%;
        position: relative;
      }
      .att-name {
        z-index: 2000;
        position: absolute;
        background: #FFF;
        margin: -10px 0px;
      }
      .att-table-name::before {
        border-bottom: 1px dotted #cccccc;
        bottom: 0.2em;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
      }
      .att-table-value {
        color:#222222;
        width: 50%;
      }
      <!--
      <div class="row">
        <div class="col-12">
          <div class="att-header">
            Характеристики
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-for="(value, name) in item.features[0]" v-bind:key="name">
            <div class="att-line" v-if="name != ''">
              <table class="att-table">
                <tr>
                  <td class="att-table-name">
                    <div class="att-table-name-container">
                      <span class="att-name">{{ name }}</span>
                    </div>
                  </td>
                  <td></td>
                  <td class="att-table-value">
                    {{ value }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      -->
      /*
            // Подкатегории
            this.subcategories = response.data.fullCategory.categories;

            // Вытаскиваем f_id подкатегорий для передачи в фильтр
            for (let s = 0; s < this.subcategories.length; s++) {
              // Объект для поиска в фильтре
              let subCatFilter = {
                "folder": this.subcategories[s].f_id
              };
              this.subcategories_fid.push(subCatFilter);
            }

            this.attributes = response.data.fullCategory.attributes[0].attributes;

            // Вытаскиваем атрибут размер для фильтра поиска
            for (let z = 0; z < this.attributes.length; z++) {

              if (this.attributes[z].name === "Размер") {

                this.attributeSize = this.attributes[z].att

              }

            }

            // Получаем минимальное и максимальное значение цены
            let allPrices = [];
            for (let p = 0; p < this.products.length; p++) {

              this.products[p].prices[0].price = parseFloat(this.products[p].prices[0].price.replace(/,/, '.').replace(/\s/g, ''));
              allPrices.push(this.products[p].prices[0].price);

            }
            // Устанавливаем значения
            this.minPriceFilterPlaceholder = Math.min.apply(Math, allPrices);
            this.maxPriceFilterPlaceholder = Math.max.apply(Math, allPrices);

            this.thisCategory = response.data.thisCategory;

 */
</style>