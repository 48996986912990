// Transport for FileManager images and other

export default {

    state: {

        imagesState: [],

    },
    mutations: {

        // Add product Images
        ADD_PRODUCT_IMAGES (state, Data) {

           state.imagesState.push(Data);

        },
        UNSET_ONE_PRODUCT_IMAGES (state){

            state.imagesState.splice(-1,1);

        },
        UNSET_PRODUCT_IMAGES (state){

           state.imagesState = [];

        },

    },
    actions: {

        add_image_to_product({ commit }, payload) {

            commit("ADD_PRODUCT_IMAGES", payload);

        },
        unset_one_image({ commit }) {

            commit("UNSET_ONE_PRODUCT_IMAGES");

        },
        unset_images({ commit }) {

            commit("UNSET_PRODUCT_IMAGES");

        },

    },
    getters: {

    }

}