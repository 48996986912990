<template>
  <div id="catalog-menu">
    <div class="container">
      <div class="row">
        <div class="col-sm-3">
          <div class="row">
            <div class="col-2">
              <img src="/img/catmenu/ankery.jpg" alt="анкеры">
            </div>
            <div class="col-10 hpnavcattype">
              <router-link to="/catalog/ankery/">Анкеры</router-link>
            </div>
          </div>
          <router-link to="/catalog/anker-gilzy/">Анкер гильзы</router-link><br>
          <router-link to="/catalog/ankernye-bolty/">Анкерные болты</router-link><br>
          <router-link to="/catalog/klinovye-ankery/">Клиновые анкеры</router-link><br>
          <router-link to="/catalog/khimicheskie-ankery/">Химические анкеры</router-link><br>
          <router-link to="/catalog/ankernye-gvozdi/">Анкерные гвозди</router-link><br>
          <router-link to="/catalog/ankery-latunnye/">Анкеры латунные</router-link><br>
          <router-link to="/catalog/ankery-potolochnye/">Анкеры потолочные</router-link><br>
          <router-link to="/catalog/ankery-ramnye/">Анкеры рамные</router-link><br>
          <router-link to="/catalog/ankery-s-podrezkoy/">Анкеры с подрезкой</router-link><br>
          <router-link to="/catalog/balzatoplastikovye-ankery/">Базальтопластиковые анкеры</router-link><br>
          <router-link to="/catalog/bolty-fundamentnye/">Болты фундаментные</router-link><br>
          <router-link to="/catalog/bystrofiksiruyushchie-gvozdi/">Быстрофиксирующие гвозди</router-link><br>
          <router-link to="/catalog/zabivnye-ankery/">Забивные анкеры</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="нержавеющий крепеж" src="/img/catmenu/ner.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/nerzhaveyushchiy-krepezh/">Нержавеющий крепеж</router-link>
            </div>
          </div>
          <router-link to="/catalog/bolty-nerzhaveyushchie/">Болты нержавеющие</router-link><br>
          <router-link to="/catalog/vinty-nerzhaveyushchie/">Винты нержавеющие</router-link><br>
          <router-link to="/catalog/gayki-nerzhaveyushchie/">Гайки нержавеющие</router-link><br>
          <router-link to="/catalog/koltsa-nerzhaveyushchie/">Кольца нержавеющие</router-link><br>
          <router-link to="/catalog/shayby-nerzhaveyushchie/">Шайбы нержавеющие</router-link><br>
          <router-link to="/catalog/shpilki-nerzhaveyushchie/">Шпильки нержавеющие</router-link><br>
          <router-link to="/catalog/shplinty-nerzhaveyushchie/">Шплинты нержавеющие</router-link><br>
          <router-link to="/catalog/shtifty-nerzhaveyushchie/">Штифты нержавеющие</router-link><br>
          <router-link to="/catalog/takelazh-nerzhaveyushchiy/">Такелаж нержавеющий</router-link><br>
          <router-link to="/catalog/samorezy-iz-nerzhaveyushchey-stali/">Саморезы из нержавеющей стали</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="электромонтажный крепеж" src="/img/catmenu/elek.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/elektromontazhnyy-krepezh/">Электромонтажный крепеж</router-link>
            </div>
          </div>
          <router-link to="/catalog/alyuminievye-skoby/">Алюминиевые скобы</router-link><br>
          <router-link to="/catalog/vyazalnaya-provoloka/">Вязальная проволока</router-link><br>
          <router-link to="/catalog/uplotnitelnye-vtulki/">Уплотнительные втулки</router-link><br>
          <router-link to="/catalog/kabelnye-styazhki/">Кабельные стяжки</router-link><br>
          <router-link to="/catalog/skoby-dlya-kabelya-plastikovye/">Скобы пластиковые для кабеля</router-link><br>
          <router-link to="/catalog/lenty-perforirovannye/">Ленты перфорированные</router-link><br>
          <router-link to="/catalog/fiksatory/">Фиксаторы</router-link><br>
          <router-link to="/catalog/skoby-kabelnye/">Скобы кабельные</router-link><br>
          <router-link to="/catalog/khomuty-trubnye/">Хомуты трубные</router-link><br>
          <br>				</div>
        <div class="col-sm-3">
          <div class="row">
            <div class="col-xs-2">
              <img src="/img/catmenu/dubeli.jpg" alt="дюбели">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/dyubeli/">Дюбели</router-link>
            </div>
          </div>
          <router-link to="/catalog/dyubeli-dlya-gazobetona/">Дюбели для газобетона</router-link><br>
          <router-link to="/catalog/dyubeli-dlya-gipsokartona/">Дюбели для гипсокартона</router-link><br>
          <router-link to="/catalog/dyubeli-dlya-stroitelnykh-lesov-mgd/">Дюбели для строительных лесов</router-link><br>
          <router-link to="/catalog/dyubeli-dlya-teploizolyatsii/">Дюбели для теплоизоляции</router-link><br>
          <router-link to="/catalog/dyubeli-raspornye/">Дюбели распорные</router-link><br>
          <router-link to="/catalog/dyubeli-universalnye/">Дюбели универсальные</router-link><br>
          <router-link to="/catalog/dyubeli-fasadnye/">Дюбели фасадные</router-link><br>
          <router-link to="/catalog/dyubel-gvozdi/">Дюбель-гвозди</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="метрический крепеж" src="/img/catmenu/metr.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/metricheskiy-krepezh/">Метрический крепеж</router-link>
            </div>
          </div>
          <router-link to="/catalog/bolty/">Болты</router-link><br>
          <router-link to="/catalog/vinty/">Винты</router-link><br>
          <router-link to="/catalog/gayki/">Гайки</router-link><br>
          <router-link to="/catalog/koltsa/">Кольца</router-link><br>
          <router-link to="/catalog/shayby/">Шайбы</router-link><br>
          <router-link to="/catalog/shpilki/">Шпильки</router-link><br>
          <router-link to="/catalog/shplinty/">Шплинты</router-link><br>
          <router-link to="/catalog/shtifty/">Штифты</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="деревянное домостроение" src="/img/catmenu/der.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/krepezh-dlya-derevyannogo-domostroeniya/">Деревянное домостроение</router-link>
            </div>
          </div>
          <router-link to="/catalog/perforirovannyy-krepezh/">Перфорированный крепеж</router-link><br>
          <router-link to="/catalog/klyaymery/">Кляймеры</router-link><br>
          <router-link to="/catalog/kreplenie-eurotec/">Крепление Eurotec</router-link><br>
          <router-link to="/catalog/kreplenie-knapp/">Крепление Knapp</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="сантехнический крепеж" src="/img/catmenu/santeh.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/santekhnicheskiy-krepezh/">Сантехнический крепеж</router-link>
            </div>
          </div>
          <router-link to="/catalog/krestiki-dlya-plitki/">Крестики для плитки</router-link><br>
          <router-link to="/catalog/nabor-krepleniy/">Набор креплений</router-link><br>
          <router-link to="/catalog/khomuty/">Хомуты</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="крепеж окрашенный" src="/img/catmenu/okr.svg">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/krepezh-okrashennyy/">Крепеж окрашенный</router-link>
            </div>
          </div>
          <router-link to="/catalog/shurupy-okrashennye/">Шурупы окрашенные</router-link><br>
          <router-link to="/catalog/zaklepki-okrashennye/">Заклепки окрашенные</router-link><br>
          <br>
          <br>				</div>
        <div class="col-sm-3">
          <div class="row">
            <div class="col-xs-2">
              <img src="/img/catmenu/zak.png" alt="заклепки">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/zaklepki/">Заклепки</router-link>
            </div>
          </div>
          <router-link to="/catalog/zaklepki-vytyazhnye/">Заклепки вытяжные</router-link><br>
          <router-link to="/catalog/zaklepki-zabivnye-pod-molotok/">Заклепки забивные</router-link><br>
          <router-link to="/catalog/zaklepki-rezbovye/">Заклепки резьбовые</router-link><br>
          <router-link to="/catalog/vtulki/">Втулки</router-link><br>
          <br>

          <div class="row">
            <div class="col-xs-2">
              <img src="/img/catmenu/tak.png" alt="такелаж">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/takelazh/">Такелаж</router-link>
            </div>
          </div>
          <router-link to="/catalog/kanaty-verevki/">Канаты/Веревки</router-link><br>
          <router-link to="/catalog/stropy/">Стропы</router-link><br>
          <router-link to="/catalog/vertlyugi/">Вертлюги</router-link><br>
          <router-link to="/catalog/zazhimy/">Зажимы</router-link><br>
          <router-link to="/catalog/karabiny/">Карабины</router-link><br>
          <router-link to="/catalog/koushi/">Коуши</router-link><br>
          <router-link to="/catalog/kryuchki-i-koltsa/">Крючки и кольца</router-link><br>
          <router-link to="/catalog/rym-gayki-bolty/">Рым-гайки/болты</router-link><br>
          <router-link to="/catalog/skoby-takelazhnye/">Скобы такелажные</router-link><br>
          <router-link to="/catalog/takelazhnye-bloki/">Такелажные блоки</router-link><br>
          <router-link to="/catalog/talrepy/">Талрепы</router-link><br>
          <router-link to="/catalog/trosy/">Тросы</router-link><br>
          <router-link to="/catalog/tsepi-i-zvenya/">Цепи и звенья</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img src="/img/catmenu/arm.png" alt="армирующие материалы">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/armiruyushchie-materialy/">Армирующие материалы</router-link>
            </div>
          </div>
          <br>


          <div class="row">
            <div class="col-xs-2">
              <img src="/img/catmenu/instrument.png" alt="лестницы строительные, стремянки">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/instrument/">Инструмент</router-link>
            </div>
          </div><br>
          <router-link to="/catalog/ruchnoy-instrument/">Ручной инструмент</router-link><br>
          <router-link to="/catalog/elektroinstrument/">Электроинструмент</router-link><br>
          <br>				</div>
        <div class="col-sm-3">
          <div class="row">
            <div class="col-xs-2">
              <img alt="успей купить" src="/img/catmenu/procent.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link style="color:#FF0000; font-size:18px;" to="/catalog/ankery">Успей купить!</router-link>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img alt="шурупы, саморезы" src="/img/catmenu/sam.png">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/shurupy-samorezy/">Шурупы, саморезы</router-link>
            </div>
          </div>
          <router-link to="/catalog/universalnye-samorezy/">Универсальные саморезы</router-link><br>
          <router-link to="/catalog/krovelnye-samorezy/">Кровельные саморезы</router-link><br>
          <router-link to="/catalog/shurupy-po-betonu/">Шурупы по бетону</router-link><br>
          <router-link to="/catalog/samorezy-dlya-gipsokartonnykh-listov/">Саморезы для гипсокартонных листов</router-link><br>
          <router-link to="/catalog/samorezy-dlya-gipsovoloknistykh-listov-gvl/">Саморезы для гипсоволокнистых листов листов</router-link><br>
          <router-link to="/catalog/shurupy-dlya-krepleniya-dereva-k-metallu/">Шурупы для крепления дерева к металлу</router-link><br>
          <router-link to="/catalog/dlya-krepleniya-izolyatsionnykh-materialov/">Для крепления изоляционных материалов</router-link><br>
          <router-link to="/catalog/shurupy-po-legkomu-betonu/">Шурупы по легкому бетону</router-link><br>
          <router-link to="/catalog/samorezy-dlya-okonnykh-i-dvernykh-korobok/">Саморезы для дверных и оконных коробок</router-link><br>
          <router-link to="/catalog/shurupy-dlya-sendvich-paneley/">Шурупы для сэндвич панелей</router-link><br>
          <router-link to="/catalog/samorezy-dlya-tonkikh-listov-li-klopy/">Саморезы для тонких листов (Li/Клопы)</router-link><br>
          <router-link to="/catalog/konstruktsionnye-shurupy-heco-topix/">Конструкционные шурупы HECO</router-link><br>
          <router-link to="/catalog/konstruktsionnye-shurupy-spax/">Конструкционные шурупы SPAX</router-link><br>
          <router-link to="/catalog/finishnye-samorezy/">Финишные саморезы</router-link><br>
          <router-link to="/catalog/shurup-shpilka-santekhnicheskaya/">Шуруп-шпилька сантехническая</router-link><br>
          <router-link to="/catalog/potolochnyy-shurup/">Потолочный шуруп</router-link><br>
          <router-link to="/catalog/shurupy-i-samorezy-po-standartu-din/">Шурупы и саморезы по стандарту DIN</router-link><br>
          <br>
          <div class="row">
            <div class="col-xs-2">
              <img src="/img/catmenu/gvozd.png" alt="гвозди">
            </div>
            <div class="col-xs-10 hpnavcattype">
              <router-link to="/catalog/gvozdi/">Гвозди</router-link>
            </div>
          </div>
          <router-link to="/catalog/gvozdi-vintovye/">Винтовые гвозди</router-link><br>
          <router-link to="/catalog/gvozdi-dlya-montazhnogo-pistoleta/">Гвозди для монтажного пистолета</router-link><br>
          <router-link to="/catalog/gvozdi-obivochnye/">Гвозди обивочные</router-link><br>
          <router-link to="/catalog/gvozdi-tolevye/">Гвозди толевые</router-link><br>
          <router-link to="/catalog/gvozdi-finishnye/">Гвозди финишные</router-link><br>
          <router-link to="/catalog/gvozdi-shifernye/">Гвозди шиферные</router-link><br>
          <router-link to="/catalog/gvozdi-ershenye/">Ершеные гвозди</router-link><br>
          <router-link to="/catalog/gvozdi-krovelnye/">Кровельные гвозди</router-link><br>
          <router-link to="/catalog/montazhnye-patrony/">Монтажные патроны</router-link><br>
          <router-link to="/catalog/gvozdi-stroitelnye/">Строительные гвозди</router-link><br>
          <br>
          <div class="row">
            <div class="col-2">
              <img style="width:45px;" alt="готовые решения бери и крепи" src="/img/catmenu/beri-i-krepi-2.jpg">
            </div>
            <div class="col-10 hpnavcattype">
              <router-link to="/catalog/nabory-beri-i-krepi/" style="font-size:16px;">Готовые решения<br>
                «БЕРИ И КРЕПИ»</router-link>
            </div>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
        <div style="clear: both;"></div>
        <div class="menubrend">
          <div class="container">
            <hr>
            <div class="row">
              <div class="col-2">
                <img style="width:100%" alt="логотип ПАРТНЕР" src="/img/logo/partner.svg" title="ПАРТНЕР">
              </div>
              <div class="col-2">
                <img style="width:100%" alt="HECO" src="/img/logo/heco.png" title="HECO">
              </div>
              <div class="col-2">
                <a href="/catalog/dyubeli-dlya-teploizolyatsii/"><img style="width:100%" alt="логотип bau-fix" src="/img/logo/logo-bau-fix.png" title="bau-fix"></a>
              </div>
              <div class="col-2">
                <a href="/catalog/elektroinstrument/"><img style="width:100%" alt="логотип bosch" src="/img/logo/logo_bosch.png" title="BOSCH"></a>
              </div>
              <div class="col-2">
                <img style="width:100%" alt="логотип Альтернатива" src="/img/logo/logo-alternativa.png" title="Альтернатива"><br>
              </div>
              <div class="col-2">
                <img width="260" style="width:100%; height:auto;" alt="логотип бери и крепи" src="/img/logo/beri-i-krepi-2.jpg" height="260" title="Бери и крепи">
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img style="width:100%" alt="логотип NORDFOX" src="/img/logo/logo_nordfox.svg" title="NORDFOX">
              </div>
              <div class="col-2">
                <img style="width:100%" alt="ZipBolt" src="/img/logo/2d89e9695ba07a68bcf1849d45c93afa.png" title="ZipBolt">
              </div>
              <div class="col-2">
                <img style="width:100%" alt="логотип РОНСОН" src="/img/logo/logo_ronson.svg" title="РОНСОН">
              </div>
              <div class="col-2">
                <img style="width:100%" alt="логотип Нордфасад" src="/img/logo/logo_6.png" title="Нордфасад">
              </div>
              <div class="col-2">
                <img style="width:100%" alt="KNAPP" src="/img/logo/knapp.svg" title="KNAPP">			</div>
              <div class="col-2">
                <img style="width:100%" alt="логотип friulsider каталог" src="/img/logo/friulsider_w.svg" title="friulsider logo">			</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios"

export default {
  name: 'CatalogMenu',
  props: {
    msg: String
  },
  data() {
    return {
      section: "home",
      catname: "",
      catelements: [],
    }
  },
  methods: {
    async fetchCategories() {
      axios
          .get('http://192.168.0.75:3000/catalog/categories/')
          .then(response => {
            this.catname = response.data.title;
            this.catelements = response.data.category_list;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  },
  c() {
    //this.fetchCategories()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
